import { Box, Grid, Typography } from '@mui/material';
import { GetShopResponse } from 'api/dtos/payment/shop/dto/response';
import { EProductID } from 'api/dtos/payment/shop/enums';
import PlotReportImage from 'assets/images/plot-report-preview.jpg';
import ProductCard from 'components/features/ShopModal/components/ProductCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProductPlotReportProps {
  shopData: GetShopResponse;
}

const ProductPlotReport = (props: ProductPlotReportProps) => {
  const { shopData } = props;
  const product = shopData[EProductID.PLOT_REPORT];

  const { t } = useTranslation();

  return (
    <ProductCard product={product} open toggleOpen={undefined}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant='body2' color='textSecondary' sx={{ mb: 4 }}>
              {t('shop:products.plotReport.description')}
            </Typography>
            <Grid container spacing={3}>
              {['one', 'two', 'three', 'four'].map((reason) => (
                <Grid item xs={12} key={reason}>
                  <Box
                    sx={{
                      position: 'relative',
                      pl: 4,
                      '&:before': {
                        content: '"–"',
                        position: 'absolute',
                        top: -2,
                        left: 0,
                        color: 'grey.700',
                      },
                    }}
                  >
                    <Typography variant='body2' color='textSecondary'>
                      {t(`shop:products.plotReport.reasons.${reason}`)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                mb: 9,
                mx: 'auto',
                maxWidth: 200,
                '&:before': {
                  content: '""',
                  display: 'block',
                  paddingBottom: '141.4%',
                },
              }}
            >
              <Box
                component='img'
                src={PlotReportImage}
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  border: 1,
                  borderColor: 'grey.300',
                  transform: 'translate(-8px, -8px)',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 0,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 1,
                  borderColor: 'grey.300',
                  bgcolor: 'grey.100',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: -1,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 1,
                  borderColor: 'grey.300',

                  bgcolor: 'grey.100',
                  transform: 'translate(8px, 8px)',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ProductCard>
  );
};

export default ProductPlotReport;
