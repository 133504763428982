import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import LoadingSpinner from './LoadingSpinner';
import styles from './styles';

interface LoadingWrapperProps {
  loading?: boolean;
  size?: number;
  color?: string;
  opacity?: number;
  children?: JSX.Element | JSX.Element[] | ReactNode;
  sx?: any;
  contentSx?: any;
  showSpinner?: boolean;
}

const LoadingWrapper = (props: LoadingWrapperProps) => {
  const { loading, size = 24, color = 'grey.700', opacity = 0.25, children, sx, contentSx, showSpinner = true } = props;

  return (
    <Box sx={[styles.wrapper, sx]}>
      {loading && showSpinner && <LoadingSpinner size={size} color={color} />}
      <Box
        sx={(theme) => ({
          transition: theme.transitions.create('opacity'),
          ...(loading
            ? {
                opacity,
                pointerEvents: 'none',
              }
            : {}),
          ...contentSx,
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LoadingWrapper;
