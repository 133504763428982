import Billing from './billing.json';
import Compare from './compare.json';
import Dashboard from './dashboard.json';
import Forms from './forms.json';
import General from './general.json';
import Integrations from './integrations.json';
import Library from './library.json';
import Modals from './modals.json';
import Profile from './profile.json';
import Project from './project.json';
import Shop from './shop.json';
import Units from './units.json';
import User from './user.json';

const resources = {
  billing: Billing,
  compare: Compare,
  dashboard: Dashboard,
  forms: Forms,
  general: General,
  integrations: Integrations,
  library: Library,
  modals: Modals,
  profile: Profile,
  project: Project,
  user: User,
  units: Units,
  shop: Shop,
};

export default resources;
