import { alpha, createTheme, responsiveFontSizes } from '@mui/material';
import React from 'react';
import {
  BoldItalicWoff,
  BoldItalicWoff2,
  BoldWoff,
  BoldWoff2,
  ItalicWoff,
  ItalicWoff2,
  MediumItalicWoff,
  MediumItalicWoff2,
  MediumWoff,
  MediumWoff2,
  RegularWoff,
  RegularWoff2,
} from './fonts';
import { CheckboxChecked, CheckboxIndeterminate, CheckboxUnchecked } from './icons';

let theme = createTheme({
  palette: {
    background: {
      default: '#FBFBFB',
    },
    common: {
      black: '#030B0E',
      white: '#FBFBFB',
    },
    primary: {
      main: '#030B0E',
      light: '#1B2122',
      dark: '#030B0E',
      contrastText: '#FBFBFB',
    },
    secondary: {
      main: '#1FC676',
      light: '#AEEACE',
      dark: '#127646',
      contrastText: '#FBFBFB',
    },
    tertiary: {
      main: '#0A369D',
      light: '#3B5EB1',
      dark: '#082B7E',
      contrastText: '#FBFBFB',
    },
    success: {
      main: '#1FC676',
      light: '#AEEACE',
      dark: '#127646',
      contrastText: '#FBFBFB',
    },
    error: {
      main: '#FF3636',
      light: '#FF5E5E',
      dark: '#992020',
      contrastText: '#FBFBFB',
    },
    white: {
      main: '#FBFBFB',
      light: '#FFFFFF',
      dark: '#CBD2D4',
      contrastText: '#1b2122',
    },
    green: {
      50: '#f9fffb',
      100: '#EDFBF4',
      200: '#DCF6E9',
      300: '#AEEACE',
      400: '#4CD191',
      500: '#1FC676',
      600: '#199E5E',
      700: '#127646',
      800: '#0F5F38',
      900: '#0B472B',
      A100: '#DCF6E9',
      A200: '#AEEACE',
      A400: '#1FC676',
      A700: '#0B472B',
    },
    blue: {
      50: '#f8f9ff',
      100: '#ECEFF7',
      200: '#D9DFF0',
      300: '#6B86C4',
      400: '#3B5EB1',
      500: '#0A369D',
      600: '#082B7E',
      700: '#06205E',
      800: '#051A4B',
      900: '#041339',
      A100: '#D9DFF0',
      A200: '#6B86C4',
      A400: '#0A369D',
      A700: '#041339',
    },
    teal: {
      50: '#f7f8f8',
      100: '#E8EBEC',
      200: '#CBD2D4',
      300: '#A7B4B6',
      400: '#8B9C9F',
      500: '#6E8387',
      600: '#58696C',
      700: '#424E50',
      800: '#353F41',
      900: '#282F31',
      A100: '#CBD2D4',
      A200: '#A7B4B6',
      A400: '#6E8387',
      A700: '#282F31',
    },
    grey: {
      50: '#ffffff',
      100: '#fbfbfb',
      200: '#ebebeb',
      300: '#dededf',
      400: '#bebfc0',
      500: '#9d9fa0',
      600: '#7d8081',
      700: '#5c6061',
      800: '#3b4142',
      900: '#1b2122',
      A100: '#ebebeb',
      A200: '#dededf',
      A400: '#9d9fa0',
      A700: '#3b4142',
    },
    red: {
      50: '#ffecec',
      100: '#FFD7D7',
      200: '#FFAFAF',
      300: '#FF8686',
      400: '#FF5E5E',
      500: '#FF3636',
      600: '#CC2B2B',
      700: '#992020',
      800: '#661616',
      900: '#330B0B',
      A100: '#FFAFAF',
      A200: '#FF8686',
      A400: '#FF3636',
      A700: '#661616',
    },
    orange: {
      50: '#fff3e0',
      100: '#ffe0b2',
      200: '#ffcc80',
      300: '#ffb74d',
      400: '#ffa726',
      500: '#ff9800',
      600: '#fb8c00',
      700: '#f57c00',
      800: '#ef6c00',
      900: '#e65100',
      A100: '#ffd180',
      A200: '#ffab40',
      A400: '#ff9100',
      A700: '#ff6d00',
    },
    yellow: '#FCE694',
    existingBuilding: {
      attic: '#a3bae0',
      fullFloors: '#456DCF',
      firstFloor: '#3030C5',
      underground: '#2D469F',
      multiFamily: '#598AD9',
    },
    law: {
      canton: '#daf3e7',
      municipality: '#c8e3f3',
    },
  },
  shadows: [
    'none',
    '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    '0 25px 50px -12px rgb(0 0 0 / 0.25)',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
    '0 0 #0000',
  ],
  innerShadows: ['none', 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)'],
  typography: {
    fontFamily: 'Monument Grotesk, sans-serif',
  },
  constants: {
    Sidebar: {
      width: 450,
    },
    SidebarPage: {
      px: 5,
    },
    SidebarHeader: {
      height: {
        xs: 45,
        md: 55,
      },
    },
    SidebarSection: {
      px: 4,
      py: 3,
    },
    SwipeableDrawer: {
      bleeding: 78,
    },
    Toolbar: {
      height: 48,
    },
    ProjectDetailsToolbar: {
      height: 80,
    },
    ProjectModal: {
      zIndex: 1,
    },
    ProjectTopBar: {
      height: 64,
      zIndex: 2,
    },
  },
});

theme = createTheme(theme, {
  spacing: (factor: number) => 4 * factor,
  text: {
    primary: '#1b2122',
    secondary: '#5c6061',
    disabled: 'rgba(92,96,97,0.4)',
  },
  divider: theme.palette.grey[300],
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${RegularWoff2}) format('woff2'),
          url(${RegularWoff}) format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${ItalicWoff2}) format('woff2'),
          url(${ItalicWoff}) format('woff');
          font-weight: 400;
          font-style: italic;
        }

        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${MediumWoff2}) format('woff2'),
          url(${MediumWoff}) format('woff');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${MediumItalicWoff2}) format('woff2'),
          url(${MediumItalicWoff}) format('woff');
          font-weight: 500;
          font-style: italic;
        }

        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${BoldWoff2}) format('woff2'),
          url(${BoldWoff}) format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${BoldItalicWoff2}) format('woff2'),
          url(${BoldItalicWoff}) format('woff');
          font-weight: 700;
          font-style: italic;
        }
      `,
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          paddingLeft: 16,
          paddingRight: 16,
          '@media (min-width: 600px)': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          lineHeight: 1.4,
          borderRadius: 45,
          textTransform: 'none',
          '&.Mui-focusVisible': {
            outlineStyle: 'solid',
            outlineWidth: 2,
            outlineColor: theme.palette.grey[200],
          },
        },
        outlined: {
          borderColor: theme.palette.grey[300],
          '&:hover': {
            borderColor: theme.palette.grey[500],
          },
        },
        outlinedSecondary: {
          color: theme.palette.green[900],
          backgroundColor: alpha(theme.palette.green[500], 0.1),
          borderColor: theme.palette.green[500],
          '&:hover': {
            backgroundColor: alpha(theme.palette.green[500], 0.15),
            borderColor: theme.palette.green[700],
          },
        },
        outlinedError: {
          borderColor: theme.palette.red[500],
          '&:hover': {
            borderColor: theme.palette.red[500],
          },
          '&.Mui-focusVisible': {
            outlineColor: theme.palette.red[200],
          },
        },
        containedSecondary: {
          '&.Mui-focusVisible': {
            outlineColor: theme.palette.green[300],
          },
        },
        containedWhite: {
          backgroundColor: theme.palette.grey[100],
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.grey[300],
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
            borderColor: theme.palette.grey[400],
          },
        },
        outlinedTertiary: {
          borderColor: theme.palette.blue[500],
          '&:hover': {
            borderColor: theme.palette.blue[500],
          },
          '&.Mui-focusVisible': {
            outlineColor: theme.palette.blue[200],
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        overline: {
          letterSpacing: '1px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxUnchecked fontSize='small' sx={{ color: 'grey.400' }} />,
        checkedIcon: <CheckboxChecked fontSize='small' />,
        indeterminateIcon: <CheckboxIndeterminate fontSize='small' />,
      },
      styleOverrides: {
        root: {
          marginLeft: 1,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: 'grey.700',
          height: 4,
          py: 2.75,
          '& .MuiSlider-thumb': {
            width: 18,
            height: 18,
            '&:before': {
              boxShadow: 0,
            },
            '&:hover, &.Mui-focusVisible, &.Mui-active': {
              boxShadow: 'none',
              bgcolor: 'grey.800',
            },
          },
        },
        trackNormal: {
          '& .MuiSlider-track': {
            bgcolor: 'grey.700',
            opacity: 1,
          },
          '& .MuiSlider-rail': {
            bgcolor: 'grey.300',
            opacity: 1,
          },
        },
        trackInverted: {
          '& .MuiSlider-track': {
            bgcolor: 'grey.300',
            opacity: 1,
          },
          '& .MuiSlider-rail': {
            bgcolor: 'grey.700',
            opacity: 1,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1.4,
          // transition: theme.transitions.create(['color', 'border-color', 'background-color']),
          '&.MuiToggleButton-tertiary': {
            '&.Mui-selected': {
              borderColor: theme.palette.blue[500],
              '&.Mui-disabled': {
                color: theme.palette.blue[300],
                borderColor: theme.palette.blue[200],
              },
            },
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
