import { isRejectedWithValue } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { toast } from 'sonner';
import theme from 'theme/theme';
import { featureservApi } from '../services';

const isDevelopment = import.meta.env.REACT_APP_DOMAIN !== 'production';

const isToastEnabled = () => !new URLSearchParams(window.location.search).has('hideErrors');

export const toastListener = (action: any) => {
  if (isRejectedWithValue(action) && isToastEnabled()) {
    if (isDevelopment) {
      if (action.type.startsWith(featureservApi.reducerPath) && action?.payload?.originalStatus === 404) return;

      toast.error(
        `Error${action?.payload?.originalStatus ? action?.payload?.originalStatus : ''}: ${action?.meta?.arg?.endpointName}`,
        {
          id: `error-${action?.payload?.originalStatus}-${action?.meta?.arg?.endpointName}`,
          description: action?.payload?.data?.message ?? action?.payload?.error,
          action: {
            label: 'Copy',
            onClick: () => {
              navigator.clipboard.writeText(JSON.stringify(action, null, 2));
            },
          },
          actionButtonStyle: {
            color: theme.palette.red[100],
            backgroundColor: theme.palette.red[600],
          },
        },
      );
    } else {
      if (action?.meta?.arg?.type === 'mutation') {
        toast.error(action?.payload?.data?.message || i18n.t<string>('general:somethingWentWrong'));
      }
    }
  }
};
