import useAuthentication from 'hooks/useAuthentication';
import React from 'react';
import LogoSpinner from '../LogoSpinner';

interface AuthenticatorProps {
  authenticatedContent: React.ReactNode;
  content: React.ReactNode;
}

const Authenticator = (props: AuthenticatorProps) => {
  const { authenticatedContent, content } = props;

  const { isAuthLoading, isAuthenticated } = useAuthentication();

  return isAuthLoading ? (
    <LogoSpinner />
  ) : (
    <>
      {isAuthenticated && authenticatedContent}
      {content}
    </>
  );
};

export default Authenticator;
