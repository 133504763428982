import Modal from 'components/modals/Modal';
import SubscriptionModalContent from 'components/modals/SubscriptionModal/SubscriptionModalContent';
import useModals from 'hooks/useModals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EModal } from 'types/enums/Modal';

const SubscriptionModal = () => {
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.SUBSCRIPTION));
  };

  return (
    <Modal
      open={modals.subscription.open}
      handleClose={handleCloseModal}
      ContentSx={{ maxWidth: 'none !important' }}
      ContentWrapperSx={{ p: 0 }}
    >
      <SubscriptionModalContent />
    </Modal>
  );
};

export default SubscriptionModal;
