import { alpha, Theme } from '@mui/material';

const styles = {
  button: {
    fontSize: 11,
    fontWeight: 400,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 99,
    p: 0,
    transition: (theme: Theme) => theme.transitions.create(['border-color', 'background-color', 'color']),
    '&:hover': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'grey.600',
    },
    '&.Mui-focusVisible': {
      outlineStyle: 'solid',
      outlineWidth: 2,
      outlineColor: (theme: Theme) => theme.palette.blue[200],
    },
  },
  buttonSize: (size: number | null = 33) => ({
    height: size,
    width: size,
    minWidth: size,
    minHeight: size,
  }),
  buttonCircle: {
    borderRadius: 99,
  },
  buttonSquare: {
    borderRadius: 1,
  },
  buttonDark: {
    color: 'grey.900',
    borderColor: 'grey.300',
  },
  buttonLight: {
    color: 'grey.100',
    borderColor: 'grey.700',
  },
  buttonGreen: {
    color: 'green.100',
    borderColor: 'green.500',
    bgcolor: 'green.500',
    '&:hover': {
      borderColor: 'green.600',
      bgcolor: 'green.600',
      color: 'grey.100',
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => alpha(theme.palette.green[100], 1),
      bgcolor: (theme: Theme) => alpha(theme.palette.green[500], 0.5),
      borderColor: 'transparent',
    },
    '&.Mui-focusVisible': {
      outlineColor: (theme: Theme) => theme.palette.green[300],
    },
  },
  buttonBlue: {
    color: 'blue.700',
    borderColor: 'blue.500',
    bgcolor: 'blue.100',
    '&:hover': {
      borderColor: 'blue.600',
      bgcolor: 'blue.200',
    },
    '&.MuiButton-contained': {
      color: 'blue.100',
      bgcolor: 'blue.500',
      '&:hover': {
        bgcolor: 'blue.600',
      },
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => alpha(theme.palette.blue[700], 0.3),
      bgcolor: 'blue.100',
      borderColor: (theme: Theme) => alpha(theme.palette.blue[500], 0.3),
    },
  },
  buttonIcon: {
    fontSize: 19,
  },
  badge: {
    top: 6,
    right: 4,
    color: 'grey.100',
    pointerEvents: 'none',
  },
  badgeLocked: {
    '& .MuiBadge-badge': {
      top: 6,
      right: 4,
      bgcolor: 'grey.100',
      color: 'common.black',
      border: 1,
      borderColor: 'grey.300',
    },
  },
  text: {
    fontSize: 13,
  },
} as const;

export default styles;
