import { createSelector } from 'reselect';
import { ShopState } from 'state/slices/shop';
import { RootState } from '../store';

export const shopSelector: (state: RootState) => ShopState = (state: RootState) => state.shop;

export const shopBillingDetailsSelector = createSelector(shopSelector, (shop) => shop.billingDetails);

export const shopCartItemsSelector = createSelector(shopSelector, (shop) => shop.cartItems);

export const shopCheckoutSelector = createSelector(shopSelector, (shop) => shop.checkout);
