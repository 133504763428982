import { Theme } from '@mui/material';

const defaultBorderColor = 'grey.300';
const hoverBorderColor = 'grey.400';
const focusedBorderColor = 'blue.300';
const errorBorderColor = 'red.300';

const styles = {
  textField: {
    '& .MuiInputLabel-root': {
      top: 16,
      left: 0,
      transition: (theme: Theme) => theme.transitions.create('color'),
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        pl: 3.5,
        py: 1.5,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
        borderWidth: 1,
        borderColor: defaultBorderColor,
        transition: (theme: Theme) => theme.transitions.create('border-color'),
        '& legend': {
          display: 'none',
        },
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: hoverBorderColor,
        },
      },
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: 'grey.300',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: focusedBorderColor,
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineColor: (theme: Theme) => theme.palette.blue[200],
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            outlineColor: (theme: Theme) => theme.palette.red[200],
          },
        },
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: errorBorderColor,
        },
      },
      '&.MuiInputBase-multiline': {
        pt: 0,
        pl: 0,
        pb: 0,
      },
    },
  },
  textFieldWithLabel: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        pt: 6.5,
      },
    },
  },
};

export default styles;
