import { Fade, SxProps, Theme, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import React from 'react';

export interface TooltipProps extends MuiTooltipProps {
  color?: string;
  textColor?: string;
  hide?: boolean;
  popperClassName?: string;
  arrowClassName?: string;
  PopperSx?: SxProps<Theme>;
}

const Tooltip = (props: TooltipProps) => {
  const { color, hide, enterDelay = 500, PopperSx, ...otherProps } = props;

  return (
    <MuiTooltip
      enterDelay={enterDelay}
      PopperProps={{
        sx: {
          display: hide ? 'none' : 'block',
          '& .MuiTypography-root': {
            fontSize: 13,
          },
          '& .MuiTooltip-tooltip': {
            overflow: 'initial',
            color: props.textColor || 'common.white',
            bgcolor: color || 'grey.700',
          },
          '& .MuiTooltip-arrow': {
            '&:before': {
              bgcolor: color || 'grey.700',
            },
          },
          ...PopperSx,
        },
      }}
      TransitionComponent={Fade}
      disableInteractive
      {...otherProps}
    >
      {props.children}
    </MuiTooltip>
  );
};

export default Tooltip;
