const styles = {
  popper: {
    '& .MuiTooltip-tooltip': {
      display: 'flex',
      bgcolor: 'grey.100',
      border: 1,
      borderColor: 'grey.300',
      color: 'grey.900',
      borderRadius: 2,
      fontSize: 14,
      fontWeight: 400,
      p: 0,
      boxShadow: 4,
      zIndex: 'modal',
    },
    '& .MuiTooltip-arrow': {
      '&:before': {
        bgcolor: 'grey.100',
        border: 1,
        borderColor: 'grey.300',
      },
    },
  },
  popperNoPadding: {
    '& .MuiTooltip-tooltip': {
      px: 0,
      py: 0,
    },
  },
  icon: {
    fontSize: 18,
    color: 'grey.700',
  },
};

export default styles;
