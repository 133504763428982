import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE, ELanguage } from 'i18n';

export interface AppState {
  language: ELanguage;
}

const initialState: AppState = {
  language: DEFAULT_LANGUAGE,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<ELanguage>) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = appSlice.actions;
