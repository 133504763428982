import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSignIn } from 'state/slices/auth';
import store, { AppDispatch } from 'state/store';
import { firebaseAuth } from 'utils/firebase';

const CypressHelper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(firebaseAuth);

  if (window.Cypress) {
    window.store = store;
    window.dispatch = {
      login: async (email: string, password: string) => {
        dispatch(authSignIn({ signInWithEmailAndPassword, email, password }));
      },
    };
    window.navigate = navigate;
  }
  return <></>;
};

export default CypressHelper;
