import { Button, ButtonProps, Theme, Typography } from '@mui/material';
import React from 'react';

export interface TextIconButtonProps extends ButtonProps {
  text?: string;
  buttonSize?: number;
  textSize?: number;
  Icon: any;
}

const TextIconButton = React.forwardRef((props: TextIconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { text, buttonSize = 32, textSize, Icon, sx, ...otherProps } = props;

  return (
    <Button
      variant='outlined'
      size='small'
      sx={{
        width: text ? 'auto' : buttonSize,
        height: buttonSize,
        minWidth: buttonSize,
        color: 'grey.700',
        '& .MuiButton-startIcon': {
          mr: 1,
        },
        '&.Mui-focusVisible': {
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineColor: (theme: Theme) => theme.palette.blue[200],
        },
        ...sx,
      }}
      startIcon={text ? Icon : undefined}
      ref={ref}
      {...otherProps}
    >
      {!text && Icon}
      {text && (
        <Typography variant='body2' color='inherit' sx={{ fontSize: textSize }}>
          {text}
        </Typography>
      )}
    </Button>
  );
});

export default TextIconButton;
