import { useIdToken } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { authIsAuthLoadingSelector } from 'state/selectors/auth';
import { firebaseAuth } from 'utils/firebase';

export const getAuthenticatedUser = () => firebaseAuth.currentUser;

export const isUserAuthenticated = () => {
  const user = firebaseAuth.currentUser;
  return !!user;
};

export const getAccessToken = async () => {
  const user = firebaseAuth.currentUser;
  if (!user) return null;

  return await user.getIdToken();
};

const useAuthentication = () => {
  const [user, loading, error] = useIdToken(firebaseAuth);

  const isReduxAuthLoading = useSelector(authIsAuthLoadingSelector);

  return {
    accessToken: user?.getIdToken(),
    authUser: user,
    authError: error,
    isAuthLoading: loading,
    isAuthError: !!error,
    isAuthenticated: !!user && !isReduxAuthLoading,
    isAuthProviderGoogle: user?.providerData[0]?.providerId === 'google.com',
  };
};

export default useAuthentication;
