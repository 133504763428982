import { Box, keyframes, styled, Typography } from '@mui/material';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import BlackLogo from '../../theme/assets/images/logo/logo-black.svg';

const rotateMobileAnimation = keyframes`
  0% { transform: rotate(90deg) }
  15% { transform: rotate(90deg) }
  35% { transform: rotate(0deg) }
  65% { transform: rotate(0deg) }
  100% { transform: rotate(90deg) }
`;

const Wrapper = styled(Div100vh)(({ theme }) => ({
  position: 'fixed',
  zIndex: 99999,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.grey[100],
  boxSizing: 'border-box',
  overflow: 'hidden',
  overflowY: 'auto',
}));

const DeviceRestriction = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          margin: 'auto',
          maxWidth: 300,
        }}
      >
        <Box
          sx={{
            bgcolor: 'grey.100',
            p: 6,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4.5 }}>
            <Box sx={{ position: 'relative', display: 'inline-block', height: 115, width: 60 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 2,
                  borderColor: 'grey.300',
                  borderStyle: 'dotted',
                  borderRadius: 2.5,
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 2,
                  borderRadius: 3.5,
                  borderColor: 'grey.900',
                  transformOrigin: '50% 75%',
                  transform: 'rotate(90deg)',
                  bgcolor: 'grey.100',
                  animation: `${rotateMobileAnimation} 3s infinite`,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Box
                    component='img'
                    src={BlackLogo}
                    alt='Amenti'
                    sx={{
                      height: 10,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '50%',
                    height: '5%',
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                    bgcolor: 'grey.900',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Typography variant='body2' textAlign='center'>
            {t('general:pleaseRotateDeviceToContinue')}
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default DeviceRestriction;
