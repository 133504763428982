export enum EProductID {
  // project
  FEASIBILITY_STUDY = 'feasibilityStudy',
  PLOT_REPORT = 'plotReport',
  // fahrlander
  FPRE_TOTAL_PACKAGE = 'fpreTotalPackage',
  FPRE_LAGE_PARZELLE = 'fpreLageParzelle',
  FPRE_LAGE_GEMEINDE = 'fpreLageGemeinde',
  FPRE_DESIGN_KOSTEN = 'fpreDesignKosten',
  FPRE_PROJECT_KOSTEN = 'fpreProjectKosten',
  FPRE_PROJECT_RENDITE = 'fpreProjectRendite',
}

export enum EProductType {
  TOTAL = 'total',
  SUB = 'sub',
}

export enum ECartItemWarning {
  UNNECESSARY_SUBPRODUCT = 'unnecessarySubproduct',
}
