import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutViewKeySelector } from 'state/selectors/layout';
import { setLayoutView } from 'state/slices/layout';
import { RootState } from 'state/store';
import { getLayoutPathString } from './util';

export interface UseLayoutViewProps<T> {
  path: string[];
  initialView: T | null;
}

const useLayoutView = <T>(props: UseLayoutViewProps<T>) => {
  const { path, initialView } = props;

  const dispatch = useDispatch();

  const key = useMemo(() => getLayoutPathString(path), [path]);
  const layoutViewState: T = useSelector((state: RootState) => layoutViewKeySelector(state, key));

  return {
    currentView: layoutViewState === undefined ? initialView : layoutViewState,
    setView: (view: T) =>
      dispatch(
        setLayoutView({
          key,
          view,
        }),
      ),
  };
};

export default useLayoutView;
