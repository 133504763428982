import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

const useSentryContext = (name: string, object: Record<string, any>) => {
  useEffect(() => {
    Sentry.setContext(name, object);

    return () => {
      Sentry.setContext(name, null);
    };
  }, [object]);
};

export default useSentryContext;
