import { Close } from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  Dialog,
  Fade,
  Grid,
  Popover,
  PopoverProps,
  Theme,
  Typography,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React from 'react';
import CircleButton from '../CircleButton';

interface ActionConfirmationWrapperProps extends Omit<PopoverProps, 'transitionDuration'> {
  children: React.ReactNode;
  variant: ActionConfirmationProps['variant'];
}

const ActionConfirmationWrapper = (props: ActionConfirmationWrapperProps) => {
  const { children, variant, ...otherProps } = props;

  return variant === 'dialog' ? (
    <Dialog {...otherProps}>{children}</Dialog>
  ) : (
    <Popover {...otherProps}>{children}</Popover>
  );
};

export interface ActionConfirmationProps extends Omit<PopoverProps, 'open'> {
  text: string;
  acceptText: string;
  declineText: string;
  onAccept: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDecline?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  AcceptButtonProps?: ButtonProps & { 'data-cy'?: string };
  DeclineButtonProps?: ButtonProps;
  PopoverSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  maxWidth?: number;
  disableConfirmation?: boolean;
  showCloseButton?: boolean;
  popoverChildren?: React.ReactNode;
  variant?: 'popover' | 'dialog';
}

const ActionConfirmation = (props: ActionConfirmationProps) => {
  const {
    onClick,
    children,
    text,
    acceptText,
    declineText,
    onAccept,
    onDecline,
    sx,
    AcceptButtonProps,
    DeclineButtonProps,
    anchorOrigin,
    transformOrigin,
    PaperProps,
    PopoverSx,
    popoverChildren,
    maxWidth = 350,
    showCloseButton = false,
    disableConfirmation,
    variant = 'popover',
    ...otherProps
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'action-confirmation-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);

      if (onClick) {
        onClick(event);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccept = (event: React.MouseEvent<HTMLButtonElement>) => {
    onAccept?.(event);

    handleClose();
  };
  const handleDecline = (event: React.MouseEvent<HTMLButtonElement>) => {
    onDecline?.(event);

    handleClose();
  };

  return (
    <Box onClick={handleClick} sx={{ display: 'inline-block', ...sx }}>
      {!disableConfirmation && (
        <ActionConfirmationWrapper
          variant={variant}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
            ...anchorOrigin,
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
            ...transformOrigin,
          }}
          PaperProps={{
            ...PaperProps,
            sx: {
              borderRadius: 2,
              border: 1,
              borderColor: 'grey.300',
              boxShadow: 4,
              ...PaperProps?.sx,
            },
          }}
          sx={[
            PopoverSx ?? {},
            {
              '& .MuiBackdrop-root': {
                bgcolor: (theme) => alpha(theme.palette.common.black, 0.5),
              },
              zIndex: 'tooltip',
            },
          ]}
          TransitionComponent={Fade}
          {...otherProps}
        >
          <Box sx={{ p: 4, maxWidth }}>
            {showCloseButton && (
              <CircleButton
                Icon={Close}
                size={24}
                BadgeSx={{ position: 'absolute', top: 14, right: 16 }}
                sx={{ height: 24, width: 24, minWidth: 24, minHeight: 24 }}
                IconSx={{ fontSize: 16 }}
                onClick={handleClose}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ lineHeight: 1.6 }}>
                  {text}
                </Typography>
              </Grid>
              {popoverChildren && (
                <Grid item xs={12}>
                  {popoverChildren}
                </Grid>
              )}
              <Grid item xs={6}>
                <Button variant='outlined' fullWidth size='small' {...DeclineButtonProps} onClick={handleDecline}>
                  {declineText}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  fullWidth
                  {...AcceptButtonProps}
                  onClick={handleAccept}
                >
                  {acceptText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </ActionConfirmationWrapper>
      )}
      {children}
    </Box>
  );
};

export default ActionConfirmation;
