import useAuthentication from 'hooks/useAuthentication';
import { useGetUserQuery } from 'state/services/backend/endpoints/user/user';

const useUser = () => {
  const authentication = useAuthentication();
  const { isAuthenticated } = authentication;

  const { data, isLoading } = useGetUserQuery(undefined, { skip: !isAuthenticated });

  const isEmployee = !!data?.customClaims?.employee;
  const isTester = !!data?.customClaims?.tester;

  return {
    user: data,
    authentication,
    customClaims: data?.customClaims,
    isTester: isTester || isEmployee,
    isEmployee: isEmployee,
    isLoading,
  };
};

export default useUser;
