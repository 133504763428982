import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { libraryApiSlice } from 'state/services/backend/endpoints/library/library';

export interface LibraryState {
  isLoading: boolean;
}

const initialState: LibraryState = {
  isLoading: false,
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.saveCostLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.saveIncomeLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.getCostLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.getIncomeLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.createCostLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.createIncomeLibrary);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.deleteLibraryItem);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.makeDefaultLibraryItem);
    addLoaderReducerToBuilder(builder, libraryApiSlice.endpoints.duplicateLibraryItem);
  },
});

const addLoaderReducerToBuilder = (builder: ActionReducerMapBuilder<LibraryState>, endpoint: any) => {
  builder.addMatcher(endpoint.matchPending, (state) => {
    state.isLoading = true;
  });
  builder.addMatcher(endpoint.matchFulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addMatcher(endpoint.matchRejected, (state) => {
    state.isLoading = false;
  });
};
