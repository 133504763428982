import { ReportProblem } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseLayoutErrorProps {
  resetError: () => void;
}

const BaseLayoutError = (props: BaseLayoutErrorProps) => {
  const { resetError } = props;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        px: 6,
        py: 9,
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ p: 4, maxWidth: 400 }}>
        <ReportProblem sx={{ mb: 4 }} />
        <Typography variant='body2' color='textSecondary'>
          {t('general:error')}
        </Typography>
        <Button variant='outlined' sx={{ mt: 6 }} onClick={resetError}>
          {t('general:tryAgain')}
        </Button>
      </Box>
    </Box>
  );
};

export default BaseLayoutError;
