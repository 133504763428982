import * as Sentry from '@sentry/react';
import { shouldInit } from '../conditionals';

const environment = import.meta.env.REACT_APP_DOMAIN;

const initSentry = () => {
  if (shouldInit()) {
    Sentry.init({
      environment,
      // Don't forget to update the `postbuild` script in package.json if you update the release value
      release: 'release-2',
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      integrations:
        environment !== 'production'
          ? [
              Sentry.replayIntegration({
                blockAllMedia: false,
                maskAllText: false,
              }),
              Sentry.replayCanvasIntegration({}),
            ]
          : [],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

export { initSentry };
