/*
 * Generates full paths from the provided paths object
 */
export const generateFullPaths = <T extends object>(paths: T, parentPath: string = ''): T => {
  const result = {} as T;

  for (const key in paths) {
    const pathKey = key as keyof T;

    if (typeof paths[pathKey] === 'string') {
      if (key === 'ROOT') {
        result[pathKey] = parentPath.replace(/\/+/g, '/') as any;
      } else {
        result[pathKey] = `${parentPath}/${paths[pathKey]}`.replace(/\/+/g, '/') as any;
      }
    } else if (typeof paths[pathKey] === 'object') {
      result[pathKey] = generateFullPaths(
        paths[pathKey] as any,
        // @ts-ignore
        `${parentPath}/${paths[pathKey].ROOT}`,
      ) as any;
    }
  }

  return result;
};
