import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Button, Typography } from '@mui/material';
import SuccessIcon from 'components/display/SuccessIcon';
import ModalContentWrapper from 'components/features/ShopModal/components/ModalContentWrapper';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestSubscriptionMutation } from 'state/services/backend/endpoints/request/request';
import LargeField from 'theme/components/inputs/LargeField';
import * as z from 'zod';

interface IFormData {
  comment: string;
}

export const subscriptionFormSchema = z.object({
  comment: z.string(),
});

const SubscriptionModalContent = () => {
  const { t } = useTranslation();

  const [requestSubscription, { isLoading, isSuccess }] = useRequestSubscriptionMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(subscriptionFormSchema),
  });

  const onHandleSubmit = async (data: IFormData) => {
    requestSubscription({
      comment: data.comment,
    });
  };

  return (
    <ModalContentWrapper maxWidth={450} sx={{ p: 9 }}>
      {isSuccess ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
            <SuccessIcon />
          </Box>
          <Typography variant='h5' component='h2' textAlign='center' fontWeight={500} sx={{ mb: 3 }}>
            {t('modals:subscription.requestSent.title')}
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {t('modals:subscription.requestSent.description')}
          </Typography>
        </>
      ) : (
        <LoadingWrapper loading={isLoading}>
          <Box sx={{ mb: 3 }}>
            <Typography variant='h5' component='h2' textAlign='center' fontWeight={500}>
              {t('modals:subscription.title')}
            </Typography>
          </Box>
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Box mb={4}>
              <Typography variant='body1'>{t('modals:subscription.description')}</Typography>
            </Box>
            <Typography variant='body1'>{t('modals:subscription.descriptionSecond')}</Typography>
          </Box>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <LargeField
              {...register('comment')}
              label={t('modals:subscription.commentFieldLabel')}
              type='text'
              fullWidth
              multiline
              error={!!errors.comment}
            />
            <Box sx={{ mt: 6, textAlign: 'center' }}>
              <Button type='submit' variant='contained' color='secondary' size='large' disabled={isLoading}>
                {t('modals:subscription.buttonText')}
              </Button>
            </Box>
          </form>
        </LoadingWrapper>
      )}
    </ModalContentWrapper>
  );
};

export default SubscriptionModalContent;
