import { skipToken } from '@reduxjs/toolkit/query';
import useShopLocation from 'components/features/ShopModal/hooks/useShopLocation';
import Modal from 'components/modals/Modal';
import { useEffectOnce } from 'hooks/useEffectOnce';
import useModals from 'hooks/useModals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouterProvider, UNSAFE_LocationContext as LocationContext } from 'react-router-dom';
import shopRouter from 'routes/routers/shop/shopRouter';
import { toast } from 'sonner';
import { useGetShopQuery } from 'state/services/backend/endpoints/shop/shop';
import { EModal } from 'types/enums/Modal';
import { StringParam, useQueryParam } from 'use-query-params';

const ShopModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [datatransStatus] = useQueryParam('datatransStatus', StringParam);

  const { modals, closeModal } = useModals();

  const location = useShopLocation();
  const { egrids = [] } = location ?? {};
  const plots = egrids.map((egrid) => ({ egrid }));
  useGetShopQuery(
    plots.length
      ? {
          plots,
        }
      : skipToken,
  );

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.SHOP));
  };

  useEffectOnce(() => {
    if (datatransStatus === 'error') {
      toast.error(t('shop:payment.error.title'), {
        description: t('shop:payment.error.description'),
      });
    }
  }, []);

  return (
    <Modal
      open={modals.shop.open}
      handleClose={handleCloseModal}
      ContentSx={{ maxWidth: 'none !important' }}
      ContentWrapperSx={{ p: 0 }}
    >
      <LocationContext.Provider value={null as any}>
        <RouterProvider router={shopRouter} />
      </LocationContext.Provider>
    </Modal>
  );
};

export default ShopModal;
