import { Box, keyframes } from '@mui/material';
import React from 'react';
import { Check } from '@mui/icons-material';

const pulseAnimation = keyframes`
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(31, 198, 118, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(31, 198, 118, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(31, 198, 118, 0);
    }
`;

const iconPulseAnimation = keyframes`
    0% {
        transform: scale(1.1);
    }

    40% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
`;

const styles = {
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    animation: `${iconPulseAnimation} 2s infinite`,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      width: '100%',
      height: '100%',
      bgcolor: 'green.500',
      borderRadius: '100%',
    },
  },
  icon: {
    position: 'relative',
    zIndex: 1,
    color: 'green.100',
    fontSize: 24,
  },
  iconPulseWrapper: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  iconPulse: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    bgcolor: 'green.200',
    borderRadius: '50%',
    animation: `${pulseAnimation} 2s infinite`,
  },
};

const SuccessIcon = () => {
  return (
    <Box sx={styles.iconWrapper}>
      <Box sx={styles.iconPulseWrapper}>
        <Box sx={styles.iconPulse} />
      </Box>
      <Check sx={styles.icon} />
    </Box>
  );
};

export default SuccessIcon;
