const styles = {
  wrapper: {
    position: 'relative',
  },
  progressWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey.600',
  },
  contentLoading: {
    opacity: 0.25,
    pointerEvents: 'none',
  },
} as const;

export default styles;
