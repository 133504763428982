import { alpha, Theme } from '@mui/material';

const styles = {
  objectCard: {
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    transition: (theme: Theme) => theme.transitions.create(['box-shadow', 'background-color', 'border', 'opacity']),
  },
  objectCardRightButtonsHover: (id: string) => ({
    '&:hover': {
      // React's useID returns a string that is not a valid CSS class name, so we need to escape it (E.g. :r39: becomes \:r39\:)
      [`& .rightButtons--${CSS.escape(id)}`]: {
        opacity: 1,
      },
    },
  }),
  objectCardClickable: {
    '&:hover': {
      border: 1,
      borderColor: 'grey.400',
    },
  },
  objectCardHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: 'primary.main',
    px: 3,
    py: 2,
    pr: 9,
    minHeight: 50,
  },
  objectCardHeaderClickable: {
    cursor: 'pointer',
  },
  objectCardHeaderNoContent: {
    px: 3,
  },
  objectCardSelected: {
    border: 1,
    borderColor: 'blue.300',
    bgcolor: (theme: Theme) => alpha(theme.palette.blue[100], 0.1),
    boxShadow: (theme: Theme) =>
      `0px 2px 8px ${alpha(theme.palette.blue[500], 0.15)}, inset 0px 0px 15px ${alpha(theme.palette.blue[500], 0.1)}`,
    '&:hover': {
      borderColor: 'blue.500',
    },
  },
  objectCardLoading: {
    boxShadow: 'none !important',
    cursor: 'auto',
    pointerEvents: 'none',
    '&:after': {
      width: 0,
    },
    '& $buildingIndex': {
      '& .MuiTypography-root': {
        color: 'primary.main',
      },
    },
  },
  objectCardDeleteLoading: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  objectIcon: {
    display: 'flex',
    mr: 3.5,
    color: 'grey.800',
    pointerEvents: 'none',
    transition: (theme: Theme) => theme.transitions.create('color'),
  },
  objectIconSelected: {
    color: 'blue.500',
  },
  objectTitleWrapper: {
    minWidth: 0,
  },
  objectTitle: {
    fontWeight: 500,
    lineHeight: 1.2,
  },
  objectSubtitle: {
    fontSize: 11,
    color: 'grey.600',
    lineHeight: 1.2,
    mt: 0.5,
  },
  rightButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    opacity: 0,
    transition: (theme: Theme) => theme.transitions.create('opacity'),
  },
  rightButtonsOpen: {
    opacity: 1,
  },
};

export default styles;
