import { ProjectIdRequest } from 'api/dtos/general/request';
import {
  GetProjectsForDashboardRequest,
  GetProjectStaticMapInputs,
  ProjectsRequest,
  RenameProjectRequest,
} from 'api/dtos/project/input';
import { GetDashboardProjectsResponse, GetProjectStaticMapResponse } from 'api/dtos/project/output';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { EProjectsView } from 'state/slices/dashboard';
import { RootState } from 'state/store';
import { IDashboardProject } from 'types/project/project';
import { RequestMethod } from 'types/request';

import { getQueryParameters } from 'utils/url';

export const dashboardApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardProjects: builder.query<GetDashboardProjectsResponse, GetProjectsForDashboardRequest>({
      query: (body) => ({
        url: `/api/project/dashboard-projects`,
        method: RequestMethod.POST,
        body,
      }),
      providesTags: [EBackendTag.PROJECTS],
    }),
    getDashboardProject: builder.query<IDashboardProject, string | undefined>({
      query: (projectId: string) => ({
        url: `/api/project/dashboard-projects/${projectId}`,
        method: RequestMethod.GET,
      }),
    }),
    duplicateProject: builder.mutation<any, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/copy`,
        method: RequestMethod.PUT,
      }),
      invalidatesTags: [EBackendTag.PROJECTS],
    }),
    renameProject: builder.mutation<any, RenameProjectRequest>({
      query: ({ projectId, newName }) => ({
        url: `/api/project/${projectId}/rename`,
        method: RequestMethod.PUT,
        body: { name: newName },
      }),
      async onQueryStarted({ projectId, newName }, { getState, dispatch, queryFulfilled }) {
        const state = getState() as RootState;

        const { search, perPage, currentPage, plotFilter, projectsView } = state.dashboard;

        const patchResultDashboard = dispatch(
          dashboardApiSlice.util.updateQueryData(
            'getDashboardProjects',
            {
              pagination: { limit: perPage, page: currentPage },
              search,
              plotFilter,
              shared: projectsView === EProjectsView.SHARED,
            },
            (draft) => {
              const project = draft.projects.find((p) => p.id === projectId);
              if (project) project.name = newName;
            },
          ),
        );
        const patchResultCard = dispatch(
          dashboardApiSlice.util.updateQueryData('getDashboardProject', projectId, (draft) => {
            draft.name = newName;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResultDashboard.undo();
          patchResultCard.undo();
        }
      },
    }),
    deleteProjects: builder.mutation<any, ProjectsRequest>({
      query: (body) => ({
        url: `/api/project/delete-projects`,
        method: RequestMethod.DELETE,
        body,
      }),
      invalidatesTags: [EBackendTag.PROJECTS],
    }),
    deleteProject: builder.mutation<any, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/delete/`,
        method: RequestMethod.DELETE,
      }),
      invalidatesTags: [EBackendTag.PROJECTS],
    }),
    getProjectStaticMap: builder.query<GetProjectStaticMapResponse, GetProjectStaticMapInputs>({
      query: ({ projectId, params }) => ({
        url: `/api/mapbox/plot-plan/${projectId}/${getQueryParameters(params)}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.IMAGES],
    }),
  }),
});

export const {
  useGetDashboardProjectsQuery,
  useGetDashboardProjectQuery,
  useDuplicateProjectMutation,
  useRenameProjectMutation,
  useDeleteProjectsMutation,
  useDeleteProjectMutation,
  useGetProjectStaticMapQuery,
} = dashboardApiSlice;

export const invalidateProjectsQueries = () =>
  dashboardApiSlice.util.invalidateTags([EBackendTag.PROJECTS, EBackendTag.IMAGES]);

export const useGetDashboardProjectsState = dashboardApiSlice.endpoints.getDashboardProjects.useQueryState;
