import { UpdateServicesInputs } from 'api/dtos/building/building/input';
import { ProjectIdRequest } from 'api/dtos/general/request';
import { GetProjectEnergyResponse, GetSolarIrradiationResponse, UpdateServicesResponse } from 'api/dtos/project/output';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { RequestMethod } from 'types/request';

export const projectSolarApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getSolarIrradiation: builder.query<GetSolarIrradiationResponse | null, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/solar/`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.SOLAR_IRRADIATION],
    }),
    getProjectEnergy: builder.query<GetProjectEnergyResponse | null, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/energy/`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.ENERGY],
    }),
    updateServices: builder.mutation<UpdateServicesResponse, UpdateServicesInputs>({
      query: ({ projectId, buildings }) => ({
        url: `/api/project/${projectId}/energy/`,
        method: RequestMethod.POST,
        body: buildings,
      }),
      invalidatesTags: [EBackendTag.ENERGY, EBackendTag.BUILDINGS],
    }),
  }),
});

export const { useGetSolarIrradiationQuery, useGetProjectEnergyQuery, useUpdateServicesMutation } =
  projectSolarApiSlice;

export const useGetSolarIrradiationQueryState = projectSolarApiSlice.endpoints.getSolarIrradiation.useQueryState;
export const useGetProjectEnergyQueryState = projectSolarApiSlice.endpoints.getProjectEnergy.useQueryState;
