import { alpha, Theme } from '@mui/material';

const styles = {
  teaserCollapse: {
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 60,
      pointerEvents: 'none',
      background: (theme: Theme) =>
        `linear-gradient(180deg, ${alpha(theme.palette.common.white, 0)} 0, ${theme.palette.common.white} 100%)`,
      transition: (theme: Theme) => theme.transitions.create('opacity'),
    },
    '& .collapseContent': {
      pointerEvents: 'none',
    },
  },
  teaserCollapseOpen: {
    opacity: 1,
    cursor: 'auto',
    '&:before': {
      opacity: 0,
    },
    '& .collapseContent': {
      pointerEvents: 'auto',
    },
  },
};

export default styles;
