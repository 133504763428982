import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { routerSelector } from 'state/selectors/router';
import { routerSlice } from 'state/slices';

interface IRouterReduxConnector {
  children: JSX.Element;
}

const RouterReduxConnector = (props: IRouterReduxConnector) => {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const { params } = useSelector(routerSelector);
  const { setParams } = routerSlice.actions;

  useEffect(() => {
    if (!isEqual(params, urlParams)) {
      dispatch(setParams(urlParams));
    }
  }, [urlParams]);

  return props.children;
};

export default RouterReduxConnector;
