import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import appRouter from 'routes/routers/app/appRouter';
import '@amenti/cityview/dist/style.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import store from 'state/store';
import theme from 'theme';

const App = () => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={appRouter} />
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
