import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkResult } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { createNewUser } from 'api/actions/user';
import { CustomParameters, getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import { firebaseAuth } from 'utils/firebase';
import { gtagEvent, setTrafficType, setUserId } from 'utils/trackers/analytics';

interface IAuthSignInUserData {
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential | undefined>;
  email: string;
  password: string;
}
export const authSignIn = createAsyncThunk<void, IAuthSignInUserData>('signIn', async (payload, thunkAPI) => {
  try {
    const { signInWithEmailAndPassword, email, password } = payload;

    await signInWithEmailAndPassword(email, password);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

interface IAuthSignInWithGoogleUserData {
  signInWithGoogle: (
    scopes?: string[] | undefined,
    customOAuthParameters?: CustomParameters | undefined,
  ) => Promise<UserCredential | undefined>;
  language: string;
}
export const authSignInWithGoogle = createAsyncThunk<ThunkResult, IAuthSignInWithGoogleUserData>(
  'signInWithGoogle',
  async (payload, thunkAPI) => {
    try {
      const { signInWithGoogle, language } = payload;

      const response = await signInWithGoogle();
      if (!response) return thunkAPI.rejectWithValue('No response from Firebase');

      const user = response.user;

      const email = user.email;
      if (!email) return thunkAPI.rejectWithValue('No email in response');

      const additionalUserInfo = getAdditionalUserInfo(response);

      if (additionalUserInfo?.isNewUser) {
        await createNewUser({
          email: email,
          newsletter: false,
          language,
        });

        gtagEvent('ads_conversion_Sign_Up_1');
      }

      return thunkAPI.fulfillWithValue('Sign in with Google success');
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

interface IAuthSignUpRequest {
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential | undefined>;
  email: string;
  password: string;
  language: string;
  newsletter?: boolean;
}
export const authSignUp = createAsyncThunk<ThunkResult, IAuthSignUpRequest>('signUp', async (payload, thunkAPI) => {
  try {
    const { createUserWithEmailAndPassword, language } = payload;

    const email = payload.email.trim();
    const password = payload.password.trim();
    const newsletter = !!payload?.newsletter;

    const response = await createUserWithEmailAndPassword(email, password);
    if (!response) return thunkAPI.rejectWithValue('No response from Firebase');

    const additionalUserInfo = getAdditionalUserInfo(response);

    if (additionalUserInfo?.isNewUser) {
      await createNewUser({
        email,
        newsletter,
        language,
      });

      gtagEvent('ads_conversion_Sign_Up_1');
    }

    return thunkAPI.fulfillWithValue('Sign up success');
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const authSignOut = createAsyncThunk<void, void>('signOut', async (_, thunkAPI) => {
  try {
    await firebaseAuth.signOut();
    setTrafficType(false);
    setUserId(null);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export interface AuthState {
  isAuthLoading: boolean;
}

const initialState: AuthState = {
  isAuthLoading: false,
};

const startIsAuthLoadingReducer = (state: AuthState) => {
  state.isAuthLoading = true;
};

const finishIsAuthLoadingReducer = (state: AuthState) => {
  state.isAuthLoading = false;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Log In
    builder.addCase(authSignIn.pending, startIsAuthLoadingReducer);
    builder.addCase(authSignIn.rejected, finishIsAuthLoadingReducer);
    builder.addCase(authSignIn.fulfilled, finishIsAuthLoadingReducer);
    // Google Auth
    builder.addCase(authSignInWithGoogle.pending, startIsAuthLoadingReducer);
    builder.addCase(authSignInWithGoogle.rejected, finishIsAuthLoadingReducer);
    builder.addCase(authSignInWithGoogle.fulfilled, finishIsAuthLoadingReducer);
    // Sign up
    builder.addCase(authSignUp.pending, startIsAuthLoadingReducer);
    builder.addCase(authSignUp.rejected, finishIsAuthLoadingReducer);
    builder.addCase(authSignUp.fulfilled, finishIsAuthLoadingReducer);
    // Sign out
    builder.addCase(authSignOut.pending, startIsAuthLoadingReducer);
    builder.addCase(authSignOut.rejected, finishIsAuthLoadingReducer);
    builder.addCase(authSignOut.fulfilled, finishIsAuthLoadingReducer);
  },
});
