import { Nullable } from '@amenti/cityview';
import { skipToken } from '@reduxjs/toolkit/query';
import { Feature, MultiPolygon } from 'geojson';
import { useGetPlotByPointQueryState } from 'state/services/backend/endpoints/map/cadastre';
import { useGetAddressByPointQuery } from 'state/services/geoAdmin/api';
import { TCantonAbbr } from 'types/location/cantons';
import { ILngLat } from 'types/location/coordinates';
import { IProjectLocation } from 'types/project/project';
import { DelimitedArrayParam, useQueryParams } from 'use-query-params';
import { getCenter } from 'utils/gis';
import { isEmpty } from 'utils/object';
import { selectAddressFromResult } from '../util';

interface UseExploreLocationDataProps {
  lng: Nullable<number>;
  lat: Nullable<number>;
}

const useExploreLocationData = (props: UseExploreLocationDataProps) => {
  const { lng, lat } = props;

  const [{ merge = [] }] = useQueryParams({
    merge: DelimitedArrayParam,
  });

  const {
    data,
    error,
    isLoading: isPlotLoading,
    isFetching: isPlotFetching,
  } = useGetPlotByPointQueryState({ lng: lng ?? 0, lat: lat ?? 0 });

  const feature = ((data?.features?.length && data.features[0]) || {}) as Feature<MultiPolygon>;

  const center = isEmpty(feature) ? ({} as ILngLat) : getCenter(feature.geometry.coordinates[0]);

  const queryCoordinates = { lng: center.lng || lng, lat: center.lat || lat };
  const hasLocation = !!queryCoordinates.lng && !!queryCoordinates.lat;

  const { address } = useGetAddressByPointQuery(
    hasLocation ? { lng: center.lng || lng, lat: center.lat || lat } : skipToken,
    {
      selectFromResult: (response) => selectAddressFromResult(response, feature),
    },
  );

  const plotId = feature?.id as number;
  const { area, bfsnr, cantonAbbr, municipality, egrid, plotNumber } = feature?.properties ?? {};
  const coordinates: number[][] = feature?.geometry?.coordinates?.[0]?.[0]?.map((p) => [p[0], p[1]]) || [];

  const location: IProjectLocation = {
    bfsnr,
    municipality,
    centerLng: center.lng || 0,
    centerLat: center.lat || 0,
    egrids: merge?.length ? merge : egrid ? [egrid] : [],
    plot: {
      id: plotId,
      plotNumber,
      ogc_fid: plotId,
      egrid,
      area,
      polygon: coordinates,
    },
    address,
    canton: cantonAbbr as TCantonAbbr,
  };

  return {
    address,
    egrids: merge?.length ? merge : egrid ? [egrid] : [],
    error,
    plotNumber,
    hasLocationData: !!data,
    isPlotFetching,
    isPlotLoading,
    lat,
    lng,
    location,
    plotId,
    uniquePlots: [
      {
        plotNumber,
        bfsnr,
      },
    ],
  };
};

export default useExploreLocationData;
