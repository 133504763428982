import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { UserResponse } from 'api/dtos/user/response';
import useUser from 'hooks/useUser';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetUserDetailsMutation } from 'state/services/backend/endpoints/user/user';
import Tooltip from 'theme/components/display/Tooltip';
import LargeField from 'theme/components/inputs/LargeField';

export interface IPersonalDetailsFormData {
  fname?: string;
  lname?: string;
  company?: string;
  street?: string;
  postcode?: string;
  city?: string;
  phone?: string;
}

interface PersonalDetailsFormProps {
  userData?: UserResponse;
  requiredData?: Partial<Record<keyof IPersonalDetailsFormData, boolean>>;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = (props) => {
  const { userData, requiredData } = props;

  const { t } = useTranslation();

  const { user } = useUser();
  const [updateUserDetails, { isLoading, status }] = useSetUserDetailsMutation();

  const { register, handleSubmit } = useForm<IPersonalDetailsFormData>({
    defaultValues: useMemo(
      () => ({
        fname: userData?.firstName ?? '',
        lname: userData?.lastName ?? '',
        company: userData?.company ?? '',
        street: userData?.street ?? '',
        postcode: userData?.postcode ?? '',
        city: userData?.city ?? '',
        phone: userData?.phone ?? '',
      }),
      [userData],
    ),
  });

  const onSubmitHandler = (data: IPersonalDetailsFormData) => {
    const { fname, lname, company, street, postcode, city, phone } = data;

    updateUserDetails({
      firstName: fname,
      lastName: lname,
      company,
      street,
      postcode,
      city,
      phone,
    });
  };

  const isRequired = (field: keyof IPersonalDetailsFormData) => requiredData?.[field] ?? false;

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Tooltip placement='bottom-start' title={t('profile:pages.personalDetails.cantChangeEmail')} enterDelay={500}>
            <Box>
              <LargeField value={user?.email || ''} label={t('forms:fields.email.label')} disabled fullWidth />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LargeField
            {...register('fname')}
            label={t('forms:fields.firstName.label')}
            fullWidth
            required={isRequired('fname')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LargeField
            {...register('lname')}
            label={t('forms:fields.lastName.label')}
            fullWidth
            required={isRequired('lname')}
          />
        </Grid>
        <Grid item xs={12}>
          <LargeField
            {...register('phone')}
            label={t('forms:fields.phone.label')}
            fullWidth
            required={isRequired('phone')}
          />
        </Grid>
        <Grid item xs={12}>
          <LargeField
            {...register('company')}
            label={t('forms:fields.company.label')}
            fullWidth
            required={isRequired('company')}
          />
        </Grid>
        <Grid item xs={12}>
          <LargeField
            {...register('street')}
            label={t('forms:fields.streetNr.label')}
            fullWidth
            required={isRequired('street')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LargeField
            {...register('postcode')}
            label={t('forms:fields.zip.label')}
            fullWidth
            required={isRequired('postcode')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LargeField
            {...register('city')}
            label={t('forms:fields.city.label')}
            fullWidth
            required={isRequired('city')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
            <Button type='submit' variant='contained' color='secondary' disabled={isLoading} sx={{ minWidth: 150 }}>
              {isLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 24,
                    width: '100%',
                    color: 'grey.400',
                  }}
                >
                  <CircularProgress size={20} color='inherit' />
                </Box>
              ) : (
                <Box component='span'>{t('general:save')}</Box>
              )}
            </Button>
            {status === 'rejected' && (
              <Typography variant='body2' color='textSecondary' sx={{ ml: 4 }}>
                {t('general:somethingWentWrong')}
              </Typography>
            )}
            {status === 'fulfilled' && (
              <Typography variant='body2' color='textSecondary' sx={{ ml: 4 }}>
                {t('forms:messages.successfulSave')}!
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonalDetailsForm;
