import proj4 from 'proj4';
import { ILngLat, ILv95 } from 'types/location/coordinates';

export const wgsProj = '+proj=longlat +datum=WGS84 +no_defs';
export const lvProj =
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs';

proj4.defs(
  'EPSG:3857',
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
);

proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs');

proj4.defs(
  'EPSG:21781',
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs',
);

export const wgsToLV95 = (point: ILngLat): ILv95 => {
  const [x, y] = proj4(wgsProj, lvProj, [point.lng, point.lat]);
  return { x, y };
};

export const lv95ToWgs = (point: ILv95): ILngLat => {
  const [lng, lat] = proj4(lvProj, wgsProj, [point.x, point.y]);
  return { lng, lat };
};

export const wgsToLocal = (coordinates: ILngLat[], ref: ILngLat): [number, number][] => {
  let localPoints: [number, number][] = [];
  if (ref) {
    for (let i in coordinates) {
      const coordinate = coordinates[i];
      const x = (coordinate['lng'] - ref.lng) * metersDegLng(ref.lat);
      const y = (coordinate['lat'] - ref.lat) * metersDegLat(ref.lat);
      localPoints.push([x, y]);
    }
  }
  return localPoints;
};

export const localToWgs = (points: [number, number][], ref: ILngLat): [number, number][] => {
  let coordinates: [number, number][] = [];
  for (let point of points) {
    const coordinate: [number, number] = [
      point[0] / metersDegLng(ref.lat) + ref.lng,
      point[1] / metersDegLat(ref.lat) + ref.lat,
    ];
    coordinates.push(coordinate);
  }
  return coordinates;
};

const metersDegLng = (x: number): number => {
  const degrees = (x * Math.PI) / 180;
  const b = 6356752.3142;
  const a = 6378137.0;
  const eccSquared = (a * a - b * b) / (a * a);
  const eSinSquared = eccSquared * Math.pow(Math.sin(degrees), 2);

  return (((Math.PI * a) / 180) * Math.cos(degrees)) / Math.sqrt(1 - eSinSquared);
};

const metersDegLat = (x: number): number => {
  const degrees = (x * Math.PI) / 180;
  const b = 6356752.3142;
  const a = 6378137.0;
  const eccSquared = (a * a - b * b) / (a * a);
  const eSinSquared = eccSquared * Math.pow(Math.sin(degrees), 2);

  return (((Math.PI * a) / 180) * (1 - eccSquared)) / Math.pow(1 - eSinSquared, 3.0 / 2.0);
};
