import { NewUserRequest } from 'api/dtos/user/request';
import { sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { getAccessToken } from 'hooks/useAuthentication';
import superagent from 'superagent';
import { firebaseAuth } from 'utils/firebase';
import { baseUrl } from './baseUrl';

export const createNewUser = async (requestBody: NewUserRequest) => {
  const accessToken = await getAccessToken();

  return await superagent
    .post('/api/users')
    .send(requestBody)
    .set('Authorization', `Bearer ${accessToken}`)
    .use(baseUrl)
    .then((res) => res.body);
};

export const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
  await signInWithEmailAndPassword(firebaseAuth, email, oldPassword);

  const currentUser = firebaseAuth.currentUser;
  if (currentUser) {
    await updatePassword(currentUser, newPassword);
  }
};

export const resetPassword = async (email: string) => {
  await sendPasswordResetEmail(firebaseAuth, email);
};
