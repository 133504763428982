import { Box, Collapse, CollapseProps, Fade } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import styles from './styles';

interface TeaserCollapseProps extends CollapseProps {
  children?: React.ReactNode;
  open: boolean;
  hasCollapse?: boolean;
  collapsedSize?: number;
  teaser?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  unmountOnExit?: boolean;
}

const TeaserCollapse = (props: TeaserCollapseProps) => {
  const { children, open, hasCollapse = true, collapsedSize = 100, teaser, onClick, unmountOnExit } = props;

  return hasCollapse ? (
    teaser ? (
      <Collapse
        in={open}
        collapsedSize={collapsedSize}
        onClick={onClick}
        sx={[styles.teaserCollapse, open ? styles.teaserCollapseOpen : {}]}
      >
        <Box className='collapseContent'>{children}</Box>
      </Collapse>
    ) : (
      <Collapse in={open} unmountOnExit={unmountOnExit} appear={false}>
        <Fade in={open} appear={false}>
          <Box>{children}</Box>
        </Fade>
      </Collapse>
    )
  ) : (
    <Box>{children}</Box>
  );
};

export default TeaserCollapse;
