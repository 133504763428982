import AargauFlag from '../../../assets/images/cantons/AG.svg';
import AppenzellInnerrhodenFlag from '../../../assets/images/cantons/AI.svg';
import AppenzellAusserrhodenFlag from '../../../assets/images/cantons/AR.svg';
import BernFlag from '../../../assets/images/cantons/BE.svg';
import BaselLandschaftFlag from '../../../assets/images/cantons/BL.svg';
import BaselStadtFlag from '../../../assets/images/cantons/BS.svg';
import FribourgFlag from '../../../assets/images/cantons/FR.svg';
import GeneveFlag from '../../../assets/images/cantons/GE.svg';
import GlarusFlag from '../../../assets/images/cantons/GL.svg';
import GraubundenFlag from '../../../assets/images/cantons/GR.svg';
import JuraFlag from '../../../assets/images/cantons/JU.svg';
import LuzernFlag from '../../../assets/images/cantons/LU.svg';
import NeuchatelFlag from '../../../assets/images/cantons/NE.svg';
import NidwaldenFlag from '../../../assets/images/cantons/NW.svg';
import ObwaldenFlag from '../../../assets/images/cantons/OW.svg';
import StGallenFlag from '../../../assets/images/cantons/SG.svg';
import SchaffhausenFlag from '../../../assets/images/cantons/SH.svg';
import SolothurnFlag from '../../../assets/images/cantons/SO.svg';
import SchwyzFlag from '../../../assets/images/cantons/SZ.svg';
import ThurgauFlag from '../../../assets/images/cantons/TG.svg';
import TicinoFlag from '../../../assets/images/cantons/TI.svg';
import UriFlag from '../../../assets/images/cantons/UR.svg';
import VaudFlag from '../../../assets/images/cantons/VD.svg';
import WallisFlag from '../../../assets/images/cantons/VS.svg';
import ZugFlag from '../../../assets/images/cantons/ZG.svg';
import ZurichFlag from '../../../assets/images/cantons/ZH.svg';
import EmptyFlag from '../../../assets/images/cantons/empty.svg';
import { Box, BoxProps } from '@mui/material';
import React from 'react';
import { TCantonAbbr } from 'types/location/cantons';

export interface CantonFlagProps extends BoxProps {
  canton?: TCantonAbbr;
}

const CantonFlag = (props: CantonFlagProps) => {
  const { canton } = props;
  let flag;

  switch (canton) {
    case 'AG':
      flag = AargauFlag;
      break;
    case 'AI':
      flag = AppenzellInnerrhodenFlag;
      break;
    case 'AR':
      flag = AppenzellAusserrhodenFlag;
      break;
    case 'BE':
      flag = BernFlag;
      break;
    case 'BL':
      flag = BaselLandschaftFlag;
      break;
    case 'BS':
      flag = BaselStadtFlag;
      break;
    case 'FR':
      flag = FribourgFlag;
      break;
    case 'GE':
      flag = GeneveFlag;
      break;
    case 'GL':
      flag = GlarusFlag;
      break;
    case 'GR':
      flag = GraubundenFlag;
      break;
    case 'JU':
      flag = JuraFlag;
      break;
    case 'LU':
      flag = LuzernFlag;
      break;
    case 'NE':
      flag = NeuchatelFlag;
      break;
    case 'NW':
      flag = NidwaldenFlag;
      break;
    case 'SG':
      flag = StGallenFlag;
      break;
    case 'SH':
      flag = SchaffhausenFlag;
      break;
    case 'SO':
      flag = SolothurnFlag;
      break;
    case 'SZ':
      flag = SchwyzFlag;
      break;
    case 'TG':
      flag = ThurgauFlag;
      break;
    case 'OW':
      flag = ObwaldenFlag;
      break;
    case 'TI':
      flag = TicinoFlag;
      break;
    case 'UR':
      flag = UriFlag;
      break;
    case 'VD':
      flag = VaudFlag;
      break;
    case 'VS':
      flag = WallisFlag;
      break;
    case 'ZG':
      flag = ZugFlag;
      break;
    case 'ZH':
      flag = ZurichFlag;
      break;
    default:
      flag = EmptyFlag;
  }

  return (
    <Box display='flex' {...props}>
      <Box component='img' src={flag} alt={canton} width='100%' />
    </Box>
  );
};

export default CantonFlag;
