import { Box, Typography } from '@mui/material';
import React from 'react';

const styles = {
  logoWrapper: {
    position: 'relative',
    borderRadius: '50%',
    width: 30,
    height: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'grey.300',
    overflow: 'hidden',
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
};

interface IntegrationHeadProps {
  title: string;
  description: string;
  extra?: string;
  logo: string;
}

const IntegrationHead = (props: IntegrationHeadProps) => {
  const { title, description, extra, logo } = props;

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={3}>
        <Box display='flex' alignItems='center'>
          <Box sx={styles.logoWrapper} mr={3}>
            <Box component='img' src={logo} alt={title} sx={styles.logo} />
          </Box>
          <Box>
            <Typography variant='body1' component='h3' fontWeight={500}>
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>
        {extra && (
          <Typography variant='body2' color='textSecondary' sx={{ mt: 1.5 }}>
            {extra}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationHead;
