import { Skeleton } from '@mui/material';
import ObjectCard from 'pages/App/Project/views/Design/components/ObjectCard';
import React from 'react';

const ShopProductSkeleton = () => {
  return (
    <ObjectCard
      title={<Skeleton height={20} width={300} />}
      rightContent={<Skeleton height={20} width={70} />}
      rightContentVisible
    />
  );
};

export default ShopProductSkeleton;
