import { Autocomplete, Box, Popper, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchField from 'theme/components/inputs/SearchField';
import { Place as PlaceIcon } from '@mui/icons-material';
import { IDashboardListProject } from 'types/project/project';
import styles from './styles';

interface IInputValue {
  inputValue: string;
}

const filterOptions = (options: IDashboardListProject[], { inputValue }: IInputValue) =>
  matchSorter(options, inputValue, { keys: ['id', 'name', 'address'] }).sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

const AutocompletePopper = (props: any) => <Popper {...props} sx={styles.popper} />;

interface CompareItemHeaderProps {
  onChange: (e: React.SyntheticEvent, value: string | IDashboardListProject | null) => void;
  value: IDashboardListProject | undefined;
  options: IDashboardListProject[];
}

const CompareItemHeader = (props: CompareItemHeaderProps) => {
  const { onChange, options, value } = props;

  const { t } = useTranslation();

  return (
    <Autocomplete
      fullWidth
      disableClearable
      filterOptions={filterOptions}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name)}
      noOptionsText={t('compare:projectNotFound')}
      onChange={onChange}
      options={options}
      value={value}
      renderInput={(props) => (
        <SearchField
          {...props}
          autoFocus
          placeholder={t('forms:fields.projectName.label')}
          Icon={<PlaceIcon sx={{ color: 'green.500', fontSize: 22 }} />}
          fullWidth
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <Box component='li' {...props} key={`compare-item-search-${option.id}`}>
            <Box>
              {parts.map((part, index) => (
                <Typography
                  variant='body2'
                  component='span'
                  key={`${option.id}-part-${index}`}
                  fontWeight={part.highlight ? 600 : 400}
                >
                  {part.text}
                </Typography>
              ))}
              <Box sx={{ mt: 0 }}>
                <Typography variant='body2' color='textSecondary' noWrap sx={{ fontSize: 12, minWidth: 0 }}>
                  {moment(option.createdAt).format('DD.MM.YYYY')}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      PopperComponent={AutocompletePopper}
      sx={styles.autocomplete}
    />
  );
};

export default CompareItemHeader;
