import i18n from 'i18n';
import * as z from 'zod';
import { emailShape } from './shapes/email';

export const billingDetailsSchema = z
  .object({
    email: emailShape,
    fname: z.string().min(1, i18n.t('forms:fields.firstName.required')),
    lname: z.string().min(1, i18n.t('forms:fields.lastName.required')),
    company: z.string(),
    streetNr: z.string().min(1, i18n.t('forms:fields.streetNr.required')),
    zip: z.string().min(1, i18n.t('forms:fields.zip.required')),
    city: z.string().min(1, i18n.t('forms:fields.city.required')),
    agree: z.boolean(),
  })
  .refine((data) => data.agree, {
    message: i18n.t('forms:fields.termsConditions.required'),
    path: ['agree'],
  });
