import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxUnchecked = (props: SvgIconProps) => (
  <SvgIcon width='1em' height='1em' viewBox='0 0 25 25' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.695 1.986h-18a1.5 1.5 0 00-1.5 1.5v18a1.5 1.5 0 001.5 1.5h18a1.5 1.5 0 001.5-1.5v-18a1.5 1.5 0 00-1.5-1.5zm-18-1.5a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3v-18a3 3 0 00-3-3h-18z'
    />
  </SvgIcon>
);

export default CheckboxUnchecked;
