enum RequestHeader {
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

enum ContentType {
  APPLICATION_JSON = 'application/json',
}

enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type RequestBody = Record<string, any>;
type RequestResponseBody = any;
type RequestResponse<T = RequestResponseBody> = {
  response: T;
  responseStatus: number | null;
  isResponseOk: boolean;
};
type QueryParameters = Record<string, string | string[] | number | number[] | boolean | undefined>;

export { RequestHeader, ContentType, RequestMethod };
export type { RequestResponse, RequestBody, RequestResponseBody, QueryParameters };
