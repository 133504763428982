import FahrlanderModal from 'components/features/Integrations/Partners/Fahrlander/FahrlanderModal';
import ShopModal from 'components/features/ShopModal';
import useShopLocation from 'components/features/ShopModal/hooks/useShopLocation';
import CalendlyModal from 'components/modals/CalendlyModal';
import DemoModal from 'components/modals/DemoModal';
import HelpModal from 'components/modals/HelpModal';
import SubscriptionModal from 'components/modals/SubscriptionModal';
import React from 'react';

const Modals = () => {
  const location = useShopLocation();

  return (
    <>
      <CalendlyModal />
      <DemoModal />
      <FahrlanderModal />
      <HelpModal />
      <SubscriptionModal />
      {location && <ShopModal />}
    </>
  );
};

export default Modals;
