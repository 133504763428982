import { FahrlanderSubscriptionAccessRequest } from 'api/dtos/fahrlander-access/request';
import { IFahrlanderFunctions } from 'api/dtos/fahrlander-functions/response';
import { ProjectIdRequest } from 'api/dtos/general/request';
import { IPlotAccessRequest } from 'api/dtos/payment/plot-access/input';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { RequestMethod } from 'types/request';

const fahrlanderAccessApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getFahrlanderAccessForSubscription: builder.query<IFahrlanderFunctions, void>({
      query: () => ({
        url: '/api/fahrlander/access/subscription',
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.FAHRLANDER_SUBSCRIPTION_ACCESS],
    }),
    getFahrlanderAccessForPlots: builder.query<IFahrlanderFunctions, IPlotAccessRequest>({
      query: (body: IPlotAccessRequest) => ({
        url: '/api/fahrlander/access/plots',
        method: RequestMethod.POST,
        body,
      }),
      providesTags: [EBackendTag.FAHRLANDER_PLOT_ACCESS],
    }),
    getFahrlanderAccessForProject: builder.query<IFahrlanderFunctions, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/fahrlander/access/project/${projectId}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.FAHRLANDER_PROJECT_ACCESS],
    }),
    fahrlanderSubscriptionAccessRequest: builder.mutation<void, FahrlanderSubscriptionAccessRequest>({
      query: (body) => ({
        url: '/api/request/fahrlander/subscription',
        method: RequestMethod.POST,
        body,
      }),
    }),
  }),
});

export const {
  useGetFahrlanderAccessForSubscriptionQuery,
  useGetFahrlanderAccessForPlotsQuery,
  useGetFahrlanderAccessForProjectQuery,
  useFahrlanderSubscriptionAccessRequestMutation,
} = fahrlanderAccessApiSlice;
