import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxIndeterminate = (props: SvgIconProps) => (
  <SvgIcon width='1em' height='1em' viewBox='0 0 25 25' fill='none' {...props}>
    <path d='M.672 3.328a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3h-18a3 3 0 01-3-3v-18z' />
    <path fill='#FBFBFB' d='M6.095 11.453h13.152v1.75H6.095z' />
  </SvgIcon>
);

export default CheckboxIndeterminate;
