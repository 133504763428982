// TODO: Declare typescript module for polylabel
// @ts-ignore
import polylabel from '@mapbox/polylabel';
import * as turf from '@turf/turf';
import { booleanPointInPolygon, buffer, point, pointOnFeature, polygon } from '@turf/turf';
import { MultiPolygon, Polygon, Position } from 'geojson';

export const getCenter = (coordinates: Position[][]) => {
  const _polygon = polygon(coordinates);
  let [lng, lat] = polylabel(coordinates, 1);

  if (!booleanPointInPolygon(point([lng, lat]), _polygon)) {
    const plotPolygonBuffer = buffer(_polygon, -0.0005, { units: 'kilometers' });
    [lng, lat] = pointOnFeature(plotPolygonBuffer).geometry.coordinates;
  }

  return { lng, lat };
};

export const getPolygonCenter = (geometry: Polygon | MultiPolygon) => {
  if (geometry.type === 'Polygon') {
    return getCenter(geometry.coordinates);
  } else {
    // Find the polygon with the maximum area
    const { lng, lat } = geometry.coordinates.reduce(
      (acc, coords) => {
        const feature = turf.polygon(coords);
        const [lng, lat] = polylabel(coords);
        const area = turf.area(feature);
        return area > acc.area ? { lng, lat, area } : acc;
      },
      { lng: null, lat: null, area: 0 },
    );

    return { lng, lat };
  }
};
