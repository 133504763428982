import { ErrorMessage } from '@hookform/error-message';
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { FieldValues } from 'react-hook-form';

interface FieldErrorMessageProps extends TypographyProps, FieldValues {}

const FieldErrorMessage = (props: FieldErrorMessageProps) => {
  const { name, errors, ...otherProps } = props;

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => (
        <Typography variant='body2' color='error' {...otherProps}>
          {message}
        </Typography>
      )}
    />
  );
};

export default FieldErrorMessage;
