import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { FahrlanderSubscriptionAccessRequest } from 'api/dtos/fahrlander-access/request';
import { IFahrlanderFunctions } from 'api/dtos/fahrlander-functions/response';
import Logo from 'assets/images/partners/fpre.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFahrlanderSubscriptionAccessRequestMutation } from 'state/services/backend/endpoints/fahrlander/fahrlanderAccess';
import { Check } from '@mui/icons-material';
import IntegrationHead from '../../IntegrationHead';
import FahrlanderRequestForm from './FahrlanderRequestForm';
import { EFahrlanderRequestType } from './FahrlanderRequestToolbar';

const styles = {
  successMessage: {},
  successIcon: {
    color: 'green.500',
    mr: 4,
  },
  successText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    mb: 3,
  },
};

interface FahrlanderModalContentProps {
  fahrlanderAccess: IFahrlanderFunctions | undefined;
  children?: React.ReactNode;
  isLoading: boolean;
  projectId: string | undefined;
  requestType: EFahrlanderRequestType;
  isInsideProject: boolean;
}

const FahrlanderModalContent = (props: FahrlanderModalContentProps) => {
  let { fahrlanderAccess, children, isLoading, isInsideProject, requestType } = props;

  const { t } = useTranslation();

  const [
    fahrlanderSubscriptionAccessRequest,
    { isLoading: isFahrlanderSubscriptionAccessRequestLoading, status: fahrlanderSubscriptionAccessRequestStatus },
  ] = useFahrlanderSubscriptionAccessRequestMutation();

  const onSubmit = (data: FahrlanderSubscriptionAccessRequest) => {
    fahrlanderSubscriptionAccessRequest(data);
  };

  const isMutationLoading = isFahrlanderSubscriptionAccessRequestLoading;

  const status = fahrlanderSubscriptionAccessRequestStatus;

  return (
    <Box>
      <IntegrationHead
        title={t('integrations:partners.fahrlander.title')}
        description={t('integrations:partners.fahrlander.description')}
        extra={isInsideProject ? undefined : t('integrations:partners.fahrlander.extraDescription')}
        logo={Logo}
      />
      <Box sx={{ mt: 5 }}>
        {children}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', color: 'grey.500', py: 3 }}>
            <CircularProgress size={20} color='inherit' />
          </Box>
        ) : (
          fahrlanderAccess && (
            <>
              {status === QueryStatus.fulfilled ? (
                <Box sx={styles.successMessage}>
                  <Divider sx={{ my: 4.5 }} />
                  <Box>
                    <Typography variant='body1' component='h4' sx={styles.successText}>
                      <Check sx={styles.successIcon} />
                      {t('integrations:requestReceived.title')}
                    </Typography>
                    <Typography variant='body2'>{t('integrations:requestReceived.description')}</Typography>
                  </Box>
                </Box>
              ) : (
                <FahrlanderRequestForm
                  fahrlanderAccess={fahrlanderAccess}
                  isLoading={isMutationLoading}
                  onSubmit={onSubmit}
                  status={status}
                  disabled={requestType === EFahrlanderRequestType.PROJECT}
                />
              )}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default FahrlanderModalContent;
