import { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const cookieBannerName = 'amenti-cookie-banner';
const cookieBannerInteractedName = 'amenti-cookie-banner-interacted';

const cookieOptions = {
  expires: 365 * 2,
  ...(import.meta.env.REACT_APP_DOMAIN === 'production' ? { domain: '.amenti.ch' } : {}),
};

const setAmentiCookies = (value: boolean) => {
  Cookies.set(cookieBannerInteractedName, 'true', cookieOptions);
  Cookies.set(cookieBannerName, String(value), cookieOptions);
};
export const acceptCookies = () => setAmentiCookies(true);
export const declineCookies = () => {
  setAmentiCookies(false);
};

export const initialize = () => {
  if (Cookies.get(cookieBannerInteractedName) === 'true') {
    if (Cookies.get(cookieBannerName) === 'true') {
      setAmentiCookies(true);
      if (import.meta.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.initialize(import.meta.env.REACT_APP_GOOGLE_ANALYTICS);
        setTrafficType(false);
      }
    }
    return false;
  }
  return true;
};

export const gtagPageView = (pathname: string, search?: string) => {
  if (window.gaGlobal) {
    ReactGA.send({ hitType: 'pageview', page: pathname + (search || '') });
  }
};

export const gtagEvent = (name: string | UaEventOptions, category?: string, label?: string, params?: object) => {
  if (window.gaGlobal) {
    let eventParams = { category, label };
    if (params) eventParams = { ...eventParams, ...params };
    ReactGA.event(name, eventParams);
  }
};

export const setUserId = (userId: string | null) => {
  ReactGA.set({ userId });
};

export const setTrafficType = (isEmployee: boolean) => {
  ReactGA.set({ traffic_type: isEmployee ? 'internal' : 'customer' });
};
