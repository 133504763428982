import { GetShopResponse } from 'api/dtos/payment/shop/dto/response';
import { useMemo } from 'react';

const useShopCartTotalPrice = (cart: string[], shopData: GetShopResponse | undefined) => {
  return useMemo(() => {
    if (!shopData) return 0;

    return cart.reduce((acc, id) => {
      const { price } = shopData[id as keyof typeof shopData] ?? { price: 0 };

      return acc + price / 100;
    }, 0);
  }, [cart, shopData]);
};

export default useShopCartTotalPrice;
