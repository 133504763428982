import useShopLocation from 'components/features/ShopModal/hooks/useShopLocation';
import { useGetShopQueryState } from 'state/services/backend/endpoints/shop/shop';

const useShopData = () => {
  const location = useShopLocation();
  const { egrids = [] } = location ?? {};

  return useGetShopQueryState({
    plots: egrids.map((egrid) => ({ egrid })),
  });
};

export default useShopData;
