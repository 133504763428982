import { PlotByPointRequest } from 'api/dtos/map/request';
import {
  MergedPlotByIdsResponse,
  MergedPlotNeighborsResponse,
  PlotByPointResponse,
  PlotsByIdsResponse,
} from 'api/dtos/map/response';
import { backendApi } from 'state/services/backend/api';

import { getQueryParameters } from 'utils/url';

const cadastreApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlotByPoint: builder.query<PlotByPointResponse, PlotByPointRequest>({
      query: ({ lng, lat }) => `/api/cadastre/${lng}/${lat}`,
      extraOptions: { maxRetries: 3 },
    }),
    getPlotsByEgrids: builder.query<PlotsByIdsResponse, string[]>({
      query: (egrids: string[]) => `/api/cadastre/egrids${getQueryParameters({ egrids })}`,
    }),
    getMergedPlotByEgrids: builder.query<MergedPlotByIdsResponse, string[]>({
      query: (egrids: string[]) => `/api/cadastre/merged-plot-by-egrids${getQueryParameters({ egrids })}`,
    }),
    getMergedPlotNeighborsByEgrids: builder.query<MergedPlotNeighborsResponse, string[]>({
      query: (egrids: string[]) => `/api/cadastre/neighbors-by-egrids${getQueryParameters({ egrids })}`,
    }),
  }),
});

export const {
  useGetPlotByPointQuery,
  useGetPlotsByEgridsQuery,
  useGetMergedPlotByEgridsQuery,
  useGetMergedPlotNeighborsByEgridsQuery,
} = cadastreApiSlice;

export const useGetPlotByPointQueryState = cadastreApiSlice.endpoints.getPlotByPoint.useQueryState;

export const useGetMergedPlotByEgridsQueryState = cadastreApiSlice.endpoints.getMergedPlotByEgrids.useQueryState;
