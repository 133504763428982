import {
  Badge,
  BadgeProps,
  Box,
  Button,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  CircularProgressProps,
  SvgIconProps,
  SvgIconTypeMap,
  Theme,
  TooltipProps as MuiTooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React, { MouseEventHandler } from 'react';
import { LockLight } from '../../../icons';
import Tooltip from '../../display/Tooltip';
import styles from './styles';

type TButton = React.ComponentPropsWithoutRef<'button'>;

export interface CircleButtonProps extends Omit<TButton, 'title'> {
  BadgeSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  ButtonProps?: MuiButtonProps;
  CircularProgressProps?: CircularProgressProps;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  IconProps?: SvgIconProps;
  IconSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  TextProps?: TypographyProps;
  TooltipProps?: Partial<MuiTooltipProps>;
  badgeColor?: BadgeProps['color'];
  badgeContent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  color?: 'dark' | 'light' | 'blue' | 'green';
  disabled?: boolean;
  loading?: boolean;
  locked?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  shape?: 'circle' | 'square';
  size?: number;
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  text?: React.ReactNode;
  title?: React.ReactNode;
  type?: 'button' | 'submit';
  variant?: any;
}

const CircleButton = (props: CircleButtonProps) => {
  const {
    BadgeSx,
    ButtonProps,
    children,
    CircularProgressProps,
    Icon,
    IconProps,
    IconSx,
    TextProps,
    TooltipProps,
    badgeColor = 'secondary',
    badgeContent,
    className,
    color,
    disabled,
    loading,
    locked,
    onClick,
    shape,
    size,
    sx = {},
    text,
    title,
    variant = 'outlined',
    ...otherProps
  } = props;

  return (
    <Tooltip title={title || ''} hide={!title} arrow disableInteractive {...TooltipProps}>
      <Badge
        color={badgeColor}
        badgeContent={locked ? <LockLight sx={{ fontSize: 12 }} /> : badgeContent}
        sx={[
          {
            '& .MuiBadge-badge': styles.badge,
          },
          !!locked && styles.badgeLocked,
          !!BadgeSx && BadgeSx,
        ]}
      >
        <Button
          variant={variant}
          size='small'
          className={className}
          sx={[
            styles.button,
            styles.buttonSize(size),
            size !== undefined && styles.buttonSize(size),
            shape === 'circle' && styles.buttonCircle,
            shape === 'square' && styles.buttonSquare,
            color === 'light' && styles.buttonLight,
            color === 'dark' && styles.buttonDark,
            color === 'green' && styles.buttonGreen,
            color === 'blue' && styles.buttonBlue,
            sx,
          ]}
          onClick={onClick}
          disabled={locked || disabled}
          {...ButtonProps}
          {...otherProps}
        >
          {loading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size={15} color='inherit' {...CircularProgressProps} />
            </Box>
          ) : children ? (
            children
          ) : Icon ? (
            <Icon
              {...IconProps}
              sx={{
                ...styles.buttonIcon,
                ...IconSx,
              }}
            />
          ) : text ? (
            <Typography
              variant='body2'
              color='inherit'
              fontWeight={500}
              {...TextProps}
              sx={{ ...styles.text, ...TextProps?.sx }}
            >
              {text}
            </Typography>
          ) : null}
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default CircleButton;
