import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxChecked = (props: SvgIconProps) => (
  <SvgIcon width='1em' height='1em' viewBox='0 0 25 25' fill='none' {...props}>
    <path d='M.672 3.407a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3h-18a3 3 0 01-3-3v-18z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.248 8.387l-8.026 9.631-5.08-5.08 1.06-1.061 3.92 3.919 6.973-8.37 1.153.961z'
      fill='#FBFBFB'
    />
  </SvgIcon>
);

export default CheckboxChecked;
