import { Button, Typography } from '@mui/material';
import { IProduct } from 'api/dtos/payment/shop';
import UNITS from 'constants/units';
import useLayoutVisibility from 'hooks/useLayout/useLayoutVisibility';
import ObjectCard from 'pages/App/Project/views/Design/components/ObjectCard';
import { ObjectCardProps } from 'pages/App/Project/views/Design/components/ObjectCard/ObjectCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shopCartItemsSelector } from 'state/selectors/shop';
import { addItemToShopCartItems, removeItemToShopCartItems } from 'state/slices/shop';
import useDevice from 'theme/hooks/useDevice';
import { Check } from '@mui/icons-material';
import { formatNumber, getPrintValue } from 'utils/formatters';

interface ProductCardProps extends Omit<ObjectCardProps, 'title'> {
  product: IProduct;
  onCartAdd?: () => void;
  onCartRemove?: () => void;
  initialOpen?: boolean;
  desktopOnly?: boolean;
}

const ProductCard = (props: ProductCardProps) => {
  const { onCartAdd, onCartRemove, children, product, initialOpen, desktopOnly, ...otherProps } = props;
  const { id, hasAccess, price } = product;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile } = useDevice();

  const namespacePath = ['shopModal', 'productList', 'productCard'];
  const componentPath = [...namespacePath, id];

  const cartItems = useSelector(shopCartItemsSelector);
  const inCart = cartItems.includes(id);

  const {
    isOpen: isProductOpen,
    open: openProduct,
    close: closeProduct,
  } = useLayoutVisibility({
    path: [...componentPath],
    initialOpen,
  });

  const handleToggleOpen = () => {
    if (isProductOpen) {
      closeProduct();
    } else {
      openProduct();
    }
  };

  const handleToggleCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (inCart) {
      dispatch(removeItemToShopCartItems(id));
      onCartRemove?.();
    } else {
      dispatch(addItemToShopCartItems(id));
      onCartAdd?.();
    }
  };

  return (
    <ObjectCard
      title={t(`shop:products.${id}.shortTitle`)}
      subtitle={
        hasAccess ? (
          t('shop:general.purchased')
        ) : (
          <Typography variant='body2' fontWeight={500} sx={{ mt: 0.5 }}>
            {getPrintValue(formatNumber(price / 100), UNITS.CURRENCY)}
          </Typography>
        )
      }
      rightContent={
        hasAccess ? (
          <Check sx={{ color: 'green.500' }} />
        ) : desktopOnly && isMobile ? (
          <Typography variant='body2' color='textSecondary' sx={{ whiteSpace: 'nowrap' }}>
            {t('general:availableOnDesktop')}
          </Typography>
        ) : (
          <Button
            variant='outlined'
            color={inCart ? 'secondary' : 'primary'}
            sx={{ whiteSpace: 'nowrap', height: 32, minWidth: 'initial' }}
            onClick={handleToggleCart}
          >
            {inCart && <Check sx={{ fontSize: 18, mr: 1.5, ml: -1.5 }} />}
            {inCart ? t('shop:general.inCart') : t('shop:general.addToCart')}
          </Button>
        )
      }
      rightContentVisible
      open={children ? isProductOpen : false}
      toggleOpen={children ? handleToggleOpen : undefined}
      headerClick='toggle'
      {...otherProps}
    >
      {children}
    </ObjectCard>
  );
};

export default ProductCard;
