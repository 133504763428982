import { Box, Button, Typography } from '@mui/material';
import useAuthentication from 'hooks/useAuthentication';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FULL_APP_PATHS } from 'routes/routers/app/appPaths';
import Logo from 'theme/components/display/Logo';

const NotFound = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthentication();

  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100vh' width='100vw'>
      <Box display='flex' alignItems='center' flexDirection='column'>
        <Box sx={{ mb: 8 }}>
          <Logo />
        </Box>
        <Typography variant='h4' component='h1' fontWeight={500}>
          {t('general:notFoundPage:title')}
        </Typography>
        <Box mt={8}>
          {isAuthenticated ? (
            <Button variant='contained' color='secondary' component={Link} to={FULL_APP_PATHS.DASHBOARD.ROOT}>
              {t('dashboard:backTo')}
            </Button>
          ) : (
            <Button variant='contained' color='secondary' component={Link} to={FULL_APP_PATHS.AUTHENTICATION.LOGIN}>
              {t('user:logInToContinue')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
