import cloneDeep from 'lodash/cloneDeep';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { CostLibraryResponse, IncomeLibraryResponse } from 'types/library/http';
import { AbstractLibraryItem, IUserLibraryItem } from 'types/library/library';
import { RequestMethod } from 'types/request';

interface ICostLibraryArgs {
  costType: string;
  costTarget: string;
}

interface IMutateCostLibraryArgs extends ICostLibraryArgs {
  body: any;
}

interface IIncomeLibraryArgs {
  incomeType: string;
  incomeTarget: string;
}

interface IMutateIncomeLibraryArgs extends IIncomeLibraryArgs {
  body: any;
}

const sortItems = (array: IUserLibraryItem<AbstractLibraryItem>[]) => {
  return array.sort((x, y) => {
    if (x.isDefault) return -1;
    if (y.isDefault) return 1;
    if (!(x.libraryItem.scope === 'global') && y.libraryItem.scope === 'global') return 1;
    if (x.libraryItem.scope === 'global' && !(y.libraryItem.scope === 'global')) return -1;

    return Number(y.isDefault) - Number(x.isDefault);
  });
};

const getCostApiUrl = (costType: string, costTarget: string) => {
  if (costTarget === 'project') {
    if (costType === 'ebkp') {
      return '/api/library/cost/EBKP/project';
    } else {
      return '/api/library/cost/BKP/project';
    }
  } else {
    if (costType === 'ebkp') {
      return '/api/library/cost/EBKP/building';
    } else if (costType === 'm2') {
      return '/api/library/cost/BKP/building/square';
    } else {
      return '/api/library/cost/BKP/building/cube';
    }
  }
};

const getIncomeApiUrl = (incomeType: string, incomeTarget: string) => {
  if (incomeType === 'rent') {
    if (incomeTarget === 'project') {
      return `/api/library/income/rent/project`;
    } else {
      return `/api/library/income/rent/building`;
    }
  } else {
    if (incomeTarget === 'project') {
      return `/api/library/income/sale/project`;
    } else {
      return `/api/library/income/sale/building`;
    }
  }
};

export const libraryApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getCostLibrary: builder.query<any, ICostLibraryArgs>({
      providesTags: [EBackendTag.COSTS],
      transformResponse(result: CostLibraryResponse) {
        return sortItems(result);
      },
      query: (args: ICostLibraryArgs) => {
        const { costType, costTarget } = args;

        return {
          url: getCostApiUrl(costType, costTarget),
          method: RequestMethod.GET,
        };
      },
    }),
    createCostLibrary: builder.mutation<any, IMutateCostLibraryArgs>({
      invalidatesTags: [EBackendTag.COSTS],
      query: (args: any) => {
        const { costType, costTarget, body } = args;

        return {
          url: getCostApiUrl(costType, costTarget),
          method: RequestMethod.POST,
          body,
        };
      },
    }),
    saveCostLibrary: builder.mutation<any, IMutateCostLibraryArgs>({
      invalidatesTags: [EBackendTag.COSTS],
      query: (args: any) => {
        const { costType, costTarget, body } = args;
        const dto = cloneDeep(body);
        delete dto.isDefault;

        return {
          url: getCostApiUrl(costType, costTarget),
          method: RequestMethod.PUT,
          body: dto,
        };
      },
    }),
    getIncomeLibrary: builder.query<any, IIncomeLibraryArgs>({
      providesTags: [EBackendTag.INCOMES],
      transformResponse(result: IncomeLibraryResponse) {
        return sortItems(result);
      },
      query: (args: IIncomeLibraryArgs) => {
        const { incomeType, incomeTarget } = args;

        return {
          url: getIncomeApiUrl(incomeType, incomeTarget),
          method: RequestMethod.GET,
        };
      },
    }),
    createIncomeLibrary: builder.mutation<any, IMutateIncomeLibraryArgs>({
      invalidatesTags: [EBackendTag.INCOMES],
      query: (args: IMutateIncomeLibraryArgs) => {
        const { incomeType, incomeTarget, body } = args;

        return {
          url: getIncomeApiUrl(incomeType, incomeTarget),
          method: RequestMethod.POST,
          body,
        };
      },
    }),
    saveIncomeLibrary: builder.mutation<any, IMutateIncomeLibraryArgs>({
      invalidatesTags: [EBackendTag.INCOMES],
      query: (args: IMutateIncomeLibraryArgs) => {
        const { incomeType, incomeTarget, body } = args;
        const dto = cloneDeep(body);
        delete dto.isDefault;

        return {
          url: getIncomeApiUrl(incomeType, incomeTarget),
          method: RequestMethod.PUT,
          body: dto,
        };
      },
    }),
    duplicateLibraryItem: builder.mutation<any, string>({
      invalidatesTags: [EBackendTag.COSTS, EBackendTag.INCOMES],
      query: (id) => {
        return {
          url: `/api/library/duplicate`,
          method: RequestMethod.POST,
          body: { id },
        };
      },
    }),
    deleteLibraryItem: builder.mutation<any, string>({
      invalidatesTags: [EBackendTag.COSTS, EBackendTag.INCOMES],
      query: (id) => {
        return {
          url: `/api/library/${id}/delete`,
          method: RequestMethod.DELETE,
        };
      },
    }),
    makeDefaultLibraryItem: builder.mutation<any, string>({
      invalidatesTags: [EBackendTag.COSTS, EBackendTag.INCOMES],
      query: (id) => {
        return {
          url: `/api/library/${id}/default`,
          method: RequestMethod.POST,
        };
      },
    }),
  }),
});

export const {
  useGetCostLibraryQuery,
  useGetIncomeLibraryQuery,
  useSaveCostLibraryMutation,
  useSaveIncomeLibraryMutation,
  useDuplicateLibraryItemMutation,
  useDeleteLibraryItemMutation,
  useMakeDefaultLibraryItemMutation,
  useCreateCostLibraryMutation,
  useCreateIncomeLibraryMutation,
} = libraryApiSlice;
