import { RootState } from '../store';
import { createSelector } from 'reselect';
import { MapState } from 'state/slices/map';

export const mapSelector: (state: RootState) => MapState = (state: RootState) => state.map;
export const mapRulerSelector = createSelector(mapSelector, (state: MapState) => state.ruler);
export const mapControlSelector = createSelector(mapSelector, (state: MapState) => state.controls);

export const mapDrawSelector = createSelector(mapSelector, (state: MapState) => state.draw);

export const mapMergeSelector = createSelector(mapSelector, (state: MapState) => state.merge);

export const mapEditEnabledSelector = createSelector(
  mapDrawSelector,
  mapMergeSelector,
  (draw: MapState['draw'], merge: MapState['merge']) => draw.isOpen || merge.isOpen,
);

export const mapIsCenteredSelector = createSelector(mapSelector, (state: MapState) => state.isCentered);

export const mapVisibilityControlSelector = createSelector(
  mapControlSelector,
  (state: MapState['controls']) => state.visibility,
);

export const mapActivePlotEgridSelector = createSelector(mapSelector, (state: MapState) => state.merge.activePlotEgrid);

export const mapMutatedPlotSelector = createSelector(mapSelector, (state: MapState) => state.draw.mutatedPlot);

export const mapSavedMutatedPlotSelector = createSelector(mapSelector, (state: MapState) => state.draw.savedPlot);
