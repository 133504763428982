import { GlobalStyles, useTheme } from '@mui/material';
import React from 'react';
import { Toaster as SonnerToaster } from 'sonner';

const Toaster = () => {
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          '.sonner-toast': {
            fontFamily: theme.typography.fontFamily,
            '--normal-bg': theme.palette.grey[100],
            '--normal-text': theme.palette.grey[800],
            '--normal-border': theme.palette.grey[300],
            '--success-bg': theme.palette.green[200],
            '--success-text': theme.palette.green[700],
            '--success-border': theme.palette.green[300],
            '--warning-bg': theme.palette.orange[100],
            '--warning-text': theme.palette.orange[800],
            '--warning-border': theme.palette.orange[200],
            '--error-bg': theme.palette.red[50],
            '--error-text': theme.palette.red[600],
            '--error-border': theme.palette.red[100],
          },
        }}
      />
      <SonnerToaster
        richColors
        closeButton
        toastOptions={{
          className: 'sonner-toast',
        }}
      />
    </>
  );
};

export default Toaster;
