/**
 * Separates the input value with the desired separator
 * EG: 1000000 with default separator ' ' becomes 1 000 000
 * @param value Value to separate
 * @param separator Character to separate with, default is space
 */
export const numberWithSeparator = (value: number | string | null, separator: string = ' ') => {
  let parts = (value ?? '').toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join(',');
};

/**
 * Format the number based on the options provided, options include thousands separation
 * and formatting to a fixed point.
 * @param value Value to format
 * @param options Formatting options
 */
export interface FormatNumberOptions {
  thousandSeparate?: boolean;
  decimals?: number;
  showAsIs?: boolean;
}

export const formatNumber = (value?: number | string | null, options?: FormatNumberOptions): string => {
  if (value === undefined || value === null) return '';

  const { decimals = 0, showAsIs = false, thousandSeparate = true } = options ?? {};

  let formattedNumber: string;

  if (decimals !== undefined && !showAsIs) {
    if (typeof value === 'string') {
      formattedNumber = parseFloat(value).toFixed(decimals);
    } else {
      formattedNumber = value.toFixed(decimals);
    }
  } else {
    formattedNumber = String(value);
  }

  if (thousandSeparate) {
    formattedNumber = numberWithSeparator(formattedNumber);
  }

  return formattedNumber;
};

/**
 * Rounds the number to the nearest half value. Eg. 2.4 -> 2.5, 3.1 -> 3.0
 * @param value Number to round
 */
export const roundHalf = (value: number): number => Math.round(value * 2) / 2;

/**
 * Returns the value with the unit if the value is not undefined or null
 * @param value Value to print
 * @param unit Unit to print
 * @param hideValue If true, the value will be hidden
 * @param emptyValue If the value is undefined or null, this value will be printed instead
 */
export const getPrintValue = (
  value: string | number | undefined | null,
  unit?: string | null,
  hideValue?: boolean,
  emptyValue?: string,
) => {
  let printValue =
    value !== undefined && value !== null && value !== ''
      ? `${value}${unit ? ` ${unit}` : ''}`
      : emptyValue !== undefined
        ? emptyValue
        : '—';

  if (hideValue) {
    printValue = '';
  }

  return printValue;
};

/**
 * Returns the parameter values concatenated with the separator
 * @param separator Separator to use
 * @param params Parameters to concatenate
 */
export const concatenateWithSeparator = (separator: string, ...params: string[]): string => {
  return params.join(` ${separator} `);
};
