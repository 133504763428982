import { Box, Button, Fade, Modal as MuiModal, ModalProps as MuiModalProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React from 'react';
import { Close } from '@mui/icons-material';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: {
      xs: 'fixed',
      lg: 'absolute',
    },
    overflow: 'hidden',
    overflowY: 'auto',
  },
  container: {
    position: 'relative',
    m: 'auto',
    height: {
      xs: '100%',
      sm: 'auto',
    },
    '&:focus': {
      outline: 0,
    },
  },
  content: {
    display: {
      xs: 'flex',
      sm: 'block',
    },
    justifyContent: {
      xs: 'center',
      sm: 'initial',
    },
    position: 'relative',
    overflow: 'auto',
    mx: 'auto',
    width: '100%',
    maxWidth: {
      sm: 550,
    },
    borderRadius: {
      sm: 2,
    },
    bgcolor: 'grey.100',
    height: {
      xs: '100%',
      sm: 'auto',
    },
    border: {
      lg: 1,
    },
    borderColor: {
      lg: 'grey.300',
    },
    my: {
      sm: 8,
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    top: 6,
    right: 6,
    width: 24,
    height: 24,
    minWidth: 24,
    color: 'grey.700',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'grey.500',
    },
  },
};

interface ModalProps extends Omit<MuiModalProps, 'sx'> {
  open: boolean;
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  ContainerSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  ContentSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  ContentWrapperSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  handleClose: (event?: Object, reason?: string) => void;
  showCloseButton?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    children,
    open = false,
    sx,
    ContainerSx,
    ContentSx,
    ContentWrapperSx,
    handleClose,
    showCloseButton = true,
    ...otherProps
  } = props;

  return (
    <MuiModal
      open={open}
      disableEnforceFocus
      onClose={handleClose}
      closeAfterTransition
      sx={[styles.modal, sx ?? {}]}
      {...otherProps}
    >
      <Fade in={open}>
        <Box sx={[styles.container, ContainerSx ?? {}]}>
          <Box sx={[styles.content, ContentSx ?? {}]}>
            {showCloseButton && (
              <Button onClick={handleClose} variant='outlined' size='small' sx={styles.closeButton}>
                <Close sx={{ fontSize: 22 }} />
              </Button>
            )}
            <Box>
              <Box sx={{ px: 6, py: 8, ...ContentWrapperSx }}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
