import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { acceptCookies, declineCookies, initialize } from 'utils/trackers/analytics';

const CookieBanner = () => {
  const { t } = useTranslation();

  const [disableGa] = useQueryParam('disable-ga', BooleanParam);
  const [isVisible, setIsVisible] = useState(false);

  const acceptHandler = () => {
    acceptCookies();
    initialize();
    setIsVisible(false);
  };

  const declineHandler = () => {
    declineCookies();
    setIsVisible(false);
  };

  useEffect(() => {
    const showBanner = initialize();
    setIsVisible(disableGa === true ? false : showBanner);
  }, []);

  return isVisible ? (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 99999,
        right: 8,
        bottom: 8,
        padding: 8,
        borderRadius: 8,
        maxWidth: {
          sm: 600,
        },
        width: 'calc(100% - 18px)',
        backgroundColor: 'grey.900',
        boxSizing: 'border-box',
      }}
    >
      <Box mb={6}>
        <Typography variant='h4' component='h2' gutterBottom color='grey.100'>
          {t('general:cookieBanner.title')}
        </Typography>
        <Typography variant='body1' color='grey.300'>
          {t('general:cookieBanner.description')}
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={6}>
          <Grid item>
            <Button variant='contained' color='secondary' onClick={acceptHandler} data-cy={'CookieBanner-Accept'}>
              {t('general:accept')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={declineHandler} sx={{ color: 'grey.100' }}>
              {t('general:cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default CookieBanner;
