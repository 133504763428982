import { ILngLat } from 'types/location/coordinates';

export enum ERequestSomethingType {
  DEMO = 'demo',
  SERVICE = 'service',
  SUBSCRIPTION = 'subscription',
  CONTACT = 'contact',
}

export interface RequestSomethingRequest {
  fullname: string | null;
  phone: string | null;
  company: string | null;
  email: string;
  newsletterAgree: boolean | null;
  type: ERequestSomethingType;
}

export interface RequestHelpRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
}

export interface RequestSubscriptionRequest {
  comment: string;
}

export interface RequestLawRequest {
  message: string;
  requestType: 'change' | 'add';
  lawType: 'municipality' | 'canton';
  location: ILngLat;
}
