import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

const useDeepCompareCache = <T>(obj: T): T => {
  const ref = useRef<T>(obj);

  if (!isEqual(ref.current, obj)) {
    ref.current = obj;
  }

  return ref.current;
};

export default useDeepCompareCache;
