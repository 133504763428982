import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import React from 'react';

interface IProductTypeOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface ProductTypeSwitchProps extends ToggleButtonGroupProps {
  options: IProductTypeOption[];
}

const ProductTypeSwitch = (props: ProductTypeSwitchProps) => {
  const { options, ...otherProps } = props;

  return (
    <ToggleButtonGroup exclusive color='tertiary' size='small' fullWidth {...otherProps}>
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ProductTypeSwitch;
