import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import styles from './styles';

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size = 24, color = 'grey.700' } = props;

  return (
    <Box
      sx={{
        ...styles.progressWrapper,
        color,
      }}
    >
      <CircularProgress color='inherit' size={size} />
    </Box>
  );
};

export default LoadingSpinner;
