import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GetCantonDataSetByLocationRequest,
  GetFederalLawDocumentsRequest,
  GetMunicipalityDataSetByLocationRequest,
} from 'api/dtos/law/request';
import { CantonDataSetResponse, FederalLawDocumentResponse, MunicipalityDataSetResponse } from 'api/dtos/law/response';
import { RequestMethod } from 'types/request';

import { getQueryParameters } from 'utils/url';

export const lawApi = createApi({
  reducerPath: 'lawApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.REACT_APP_LAW_API_URL,
  }),
  endpoints: (builder) => ({
    getMunicipalityDataSetByLocation: builder.query<
      MunicipalityDataSetResponse,
      GetMunicipalityDataSetByLocationRequest
    >({
      query: (request) => ({
        url: `/municipalities/data-set${getQueryParameters(request, true)}`,
        method: RequestMethod.GET,
      }),
    }),
    getCantonDataSetByLocation: builder.query<CantonDataSetResponse, GetCantonDataSetByLocationRequest>({
      query: (request) => ({
        url: `/cantons/data-set${getQueryParameters(request, true)}`,
        method: RequestMethod.GET,
      }),
    }),
    getFederalLawDocuments: builder.query<FederalLawDocumentResponse[], GetFederalLawDocumentsRequest>({
      query: (request) => ({
        url: `/federal-documents${getQueryParameters(request, true)}`,
        method: RequestMethod.GET,
      }),
    }),
  }),
});

export const {
  useGetFederalLawDocumentsQuery,
  useGetCantonDataSetByLocationQuery,
  useGetMunicipalityDataSetByLocationQuery,
} = lawApi;
