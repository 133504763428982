import Modal from 'components/modals/Modal';
import useModals from 'hooks/useModals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EModal } from 'types/enums/Modal';
import HelpModalContent from './HelpModalContent';

const HelpModal = () => {
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.HELP));
  };

  return (
    <Modal
      open={modals.help.open}
      handleClose={handleCloseModal}
      ContentSx={{ maxWidth: 'none !important' }}
      ContentWrapperSx={{ p: 0 }}
    >
      <HelpModalContent />
    </Modal>
  );
};

export default HelpModal;
