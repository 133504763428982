import { Theme } from '@mui/material';

const styles = {
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      boxShadow: 4,
      '&.MuiInputBase-sizeSmall': {
        py: 0,
        borderRadius: 1,
        '& .MuiAutocomplete-input': {
          pl: 0,
          py: 2.125,
          pr: 8,
        },
      },
      '&.MuiInputBase-adornedStart': {
        pl: 1.5,
      },
      '&.MuiInputBase-adornedEnd': {
        pr: 1,
      },
    },
  },
  popper: {
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
    '& .MuiPaper-root': {
      boxShadow: 4,
      borderRadius: 1,
      bgcolor: 'common.white',
      mt: 1,
      '& .MuiAutocomplete-listbox': {
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 1,
        py: 0,
        '& li': {
          borderBottom: 1,
          borderColor: 'grey.300',
        },
      },
    },
  },
};

export default styles;
