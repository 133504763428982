import { Box, CircularProgress, Typography } from '@mui/material';
import useExploreLocationData from 'pages/App/Explore/hooks/useExploreLocationData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CantonFlag from 'theme/components/display/CantonFlag';
import Tipper from 'theme/components/display/Tipper';
import { Search } from '@mui/icons-material';
import { ILngLat } from 'types/location/coordinates';
import { IProjectLocation } from 'types/project/project';
import { Nullable } from 'types/util';

// TODO: This address parsing should be moved to backend, but currently there is no mapper for project querying
const getStreetAddress = (address: string) => address?.split(',')?.[0];
const getRegion = (address: string) => address?.split(',').slice(1).join(',');
const getAddressExtra = (region: string, municipality: string | null, canton: string, bfsnr: number) => {
  const regionFormatted = region.replace(` ${canton}`, '');

  const addressParts = [
    regionFormatted,
    !municipality || region.includes(municipality) ? undefined : `, ${municipality}`,
    ` (BFS-Nr. ${bfsnr})`,
    `, ${canton}`,
  ];

  return addressParts.join('');
};

interface LocationHeaderBaseProps {
  location: IProjectLocation;
  isLoading?: boolean;
  showEgrids?: boolean;
}

const LocationHeaderBase = (props: LocationHeaderBaseProps) => {
  const { location, isLoading, showEgrids } = props;
  const { address, municipality, bfsnr, canton, egrids } = location;
  const { t } = useTranslation();

  const region = getRegion(address);
  const streetAddress = address
    ? getStreetAddress(address)
    : location
      ? t('project:pages.location.selectedLocation')
      : t('project:pages.location.searchForAddress');
  const addressExtra = region && canton ? getAddressExtra(region, municipality, canton, bfsnr) : undefined;
  const addressExtraEgrids = `EGRID ${egrids.join(', ')}`;

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', minHeight: 48 }}
      data-cy='Location-Header'
    >
      <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: isLoading ? 'calc(100% - 60px)' : '100%' }}>
        <Box>
          <CantonFlag canton={canton} sx={{ width: { xs: 36, md: 38 }, mr: 4 }} />
        </Box>
        <Box sx={{ minWidth: 0 }}>
          <Typography variant='h6' component='h1' fontWeight={500} noWrap title={streetAddress}>
            {streetAddress}
          </Typography>
          {addressExtra && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='body2' color='textSecondary' noWrap title={addressExtra} sx={{ minWidth: 0 }}>
                {addressExtra}
              </Typography>
              {showEgrids && <Tipper content={addressExtraEgrids} sx={{ ml: 1 }} />}
            </Box>
          )}
        </Box>
      </Box>
      {isLoading && <CircularProgress size={24} sx={{ color: 'grey.700', mr: 1.5 }} />}
    </Box>
  );
};

interface LocationHeaderWithLocationProps {
  lng: ILngLat['lng'];
  lat: ILngLat['lat'];
  isLoading?: boolean;
  showEgrids?: boolean;
}

const LocationHeaderWithLocation = (props: LocationHeaderWithLocationProps) => {
  const { lng, lat, isLoading, showEgrids } = props;

  const { location } = useExploreLocationData({ lng, lat });

  return <LocationHeaderBase location={location} isLoading={isLoading} showEgrids={showEgrids} />;
};

interface LocationHeaderWithoutLocationProps {
  isLoading?: boolean;
}

const LocationHeaderWithoutLocation = (props: LocationHeaderWithoutLocationProps) => {
  const { isLoading } = props;

  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', minHeight: 48 }}
      data-cy='Location-Header'
    >
      <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: isLoading ? 'calc(100% - 60px)' : '100%' }}>
        <Search sx={{ fontSize: 28, mr: 3 }} />
        <Box>
          <Typography variant='h6' component='h1' fontWeight={500} noWrap sx={{ minWidth: 0 }}>
            {t('project:pages.location.searchForAddress')}
          </Typography>
          <Typography variant='body2' color='textSecondary' noWrap sx={{ fontSize: 13, minWidth: 0 }}>
            {t('project:pages.location.searchForAddressMobileDescription')}
          </Typography>
        </Box>
      </Box>
      {isLoading && <CircularProgress size={24} sx={{ color: 'grey.700', mr: 1.5 }} />}
    </Box>
  );
};

interface LocationHeaderProps {
  location?: IProjectLocation;
  lng?: Nullable<number>;
  lat?: Nullable<number>;
  isLoading?: boolean;
  showEgrids?: boolean;
}

const LocationHeader = (props: LocationHeaderProps) => {
  const { location, lng, lat, isLoading, showEgrids } = props;

  if (location) {
    return <LocationHeaderBase location={location} isLoading={isLoading} showEgrids={showEgrids} />;
  } else if (lat && lng) {
    return <LocationHeaderWithLocation lng={lng} lat={lat} isLoading={isLoading} showEgrids={showEgrids} />;
  } else {
    return <LocationHeaderWithoutLocation isLoading={isLoading} />;
  }
};

export default LocationHeader;
