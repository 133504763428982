import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const featureservApi = createApi({
  reducerPath: 'featureservApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.REACT_APP_PG_FEATURESERV_URL,
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
