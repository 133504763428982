import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties } from 'geojson';
import { Nullable } from 'types/util';

export enum EMapType {
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  CADASTRE = 'CADASTRE',
}

export type MapPitch = 0 | 75;

export enum EMapRulerType {
  DISTANCE = 'distance',
  AREA = 'area',
}

export enum EMapOverlay {
  CONTAMINATION = 'contamination',
  HAZARDS = 'hazards',
  HERITAGE = 'heritage',
  ORTSBILD = 'ortsbild',
  LANDSCAPE = 'landscape',
  TRAIN_NOISE_DAYTIME = 'trainNoiseDaytime',
  TRAIN_NOISE_NIGHTTIME = 'trainNoiseNighttime',
  CAR_NOISE_DAYTIME = 'carNoiseDaytime',
  CAR_NOISE_NIGHTTIME = 'carNoiseNighttime',
}

type MapVisibilityState = Record<EMapOverlay, boolean>;

export interface MapState {
  isCentered: boolean;
  controls: {
    visibility: MapVisibilityState;
    type: EMapType;
    zoom: number;
    bearing: number;
    terrain: boolean;
    pitch: MapPitch;
  };
  draw: {
    isOpen: boolean;
    mutatedPlot: Nullable<Feature<any, GeoJsonProperties>>;
    savedPlot: Nullable<Feature<any, GeoJsonProperties>>;
    measurementActive: boolean;
  };
  ruler: {
    isOpen: boolean;
    type: EMapRulerType | null;
    measuredDistance: number;
    measuredArea: number;
  };
  merge: {
    isOpen: boolean;
    activePlotEgrid: Nullable<string>;
  };
}

const savedPlot = sessionStorage.getItem('saved_plot');
sessionStorage.removeItem('saved_plot');

const initialState: MapState = {
  isCentered: true,
  controls: {
    type: EMapType.ROADMAP,
    visibility: {
      contamination: false,
      hazards: false,
      heritage: false,
      ortsbild: false,
      landscape: true,
      trainNoiseDaytime: false,
      trainNoiseNighttime: false,
      carNoiseDaytime: false,
      carNoiseNighttime: false,
    },
    zoom: 17,
    bearing: 0,
    terrain: false,
    pitch: 0,
  },
  draw: {
    isOpen: false,
    mutatedPlot: null,
    savedPlot: savedPlot ? JSON.parse(savedPlot as any) : null,
    measurementActive: false,
  },
  ruler: {
    isOpen: false,
    type: null,
    measuredDistance: 0,
    measuredArea: 0,
  },
  merge: {
    isOpen: false,
    activePlotEgrid: null,
  },
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clearMapSlice: () => initialState,
    toggleVisibilityControl(state, action: PayloadAction<EMapOverlay>) {
      state.controls.visibility[action.payload] = !state.controls.visibility[action.payload];
    },
    setVisibilityControl(state, action: PayloadAction<{ type: EMapOverlay; value: boolean }>) {
      state.controls.visibility[action.payload.type] = action.payload.value;
    },
    zoomIn(state) {
      state.controls.zoom = Math.round(state.controls.zoom) + 1;
    },
    zoomOut(state) {
      state.controls.zoom = Math.round(state.controls.zoom) - 1;
    },
    setZoom(state, action: PayloadAction<number>) {
      state.controls.zoom = action.payload;
    },
    setMapType(state, action: PayloadAction<EMapType>) {
      state.controls.type = action.payload;
    },
    toggleMapType(state) {
      state.controls.type = state.controls.type === EMapType.ROADMAP ? EMapType.SATELLITE : EMapType.ROADMAP;
    },
    setMapBearing(state, action: PayloadAction<number>) {
      state.controls.bearing = action.payload;
    },
    setMapPitch(state, action: PayloadAction<MapPitch>) {
      state.controls.pitch = action.payload;
    },
    setMapTerrain(state, action: PayloadAction<boolean>) {
      state.controls.terrain = action.payload;
    },
    setActivePlotEgrid(state, action: PayloadAction<string>) {
      state.merge.activePlotEgrid = action.payload;
    },
    openRuler(state) {
      state.ruler.isOpen = true;
      state.ruler.type = EMapRulerType.DISTANCE;
    },
    closeRuler(state) {
      state.ruler.isOpen = false;
      state.ruler.measuredDistance = 0;
      state.ruler.measuredArea = 0;
      state.ruler.type = null;
    },
    setRulerType(state, action: PayloadAction<EMapRulerType | null>) {
      state.ruler.type = action.payload;
    },
    setRulerMeasuredDistance(state, action: PayloadAction<number>) {
      state.ruler.measuredDistance = action.payload;
    },
    setRulerMeasuredArea(state, action: PayloadAction<number>) {
      state.ruler.measuredArea = action.payload;
    },
    // TODO: types
    setMutatedPlot(state, action: PayloadAction<any>) {
      state.draw.mutatedPlot = action.payload;
    },
    saveMutatedPlot(state) {
      sessionStorage.setItem('saved_plot', JSON.stringify(state.draw.mutatedPlot));
      state.draw.savedPlot = state.draw.mutatedPlot;
    },
    setSavedPlot(state, action: PayloadAction<any>) {
      state.draw.savedPlot = action.payload;
    },
    enableMapDraw(state) {
      state.draw.isOpen = true;
    },
    disableMapDraw(state) {
      state.draw.isOpen = false;
      state.draw.mutatedPlot = null;
    },
    setMergeOpen(state) {
      state.merge.isOpen = true;
    },
    setMergeClose(state) {
      state.merge.isOpen = false;
    },
    setIsCentered(state, action: PayloadAction<boolean>) {
      state.isCentered = action.payload;
    },
    toggleMapMeasurement(state) {
      state.draw.measurementActive = !state.draw.measurementActive;
    },
  },
});

export const {
  clearMapSlice,
  toggleVisibilityControl,
  setVisibilityControl,
  zoomIn,
  zoomOut,
  setZoom,
  setMapType,
  toggleMapType,
  setMapBearing,
  setMapPitch,
  setMapTerrain,
  setActivePlotEgrid,
  openRuler,
  closeRuler,
  setRulerType,
  setRulerMeasuredDistance,
  setRulerMeasuredArea,
  setMergeOpen,
  setMergeClose,
  saveMutatedPlot,
  setSavedPlot,
  setMutatedPlot,
  disableMapDraw,
  enableMapDraw,
  setIsCentered,
  toggleMapMeasurement,
} = mapSlice.actions;
