import useDeepCompareMemo from 'hooks/useDeepCompareMemo';
import { Location, matchRoutes, RouteMatch, RouteObject, useLocation } from 'react-router-dom';
import appRouter from 'routes/routers/app/appRouter';

const getCurrentPath = (location: Location, depth: number, routes: RouteObject[]) => {
  const baseMatchingRoutes = matchRoutes(routes, location);

  // Remove the first wrapper element '/' path part
  const matchingRoutes = baseMatchingRoutes?.slice(1) ?? [];

  const currentPage = matchingRoutes?.length > depth ? matchingRoutes[depth] : {};
  const { route } = (currentPage as RouteMatch) ?? {};
  const { path } = route ?? {};

  return path || '';
};

const useCurrentPath = (depth: number | number[], routes: RouteObject[] = appRouter.routes) => {
  const location = useLocation();

  return useDeepCompareMemo(() => {
    if (!Array.isArray(depth)) {
      const part = getCurrentPath(location, depth, routes);

      return {
        currentPath: part,
        currentPathParts: [part],
      };
    }

    const parts = depth.map((d) => getCurrentPath(location, d, routes)).filter(Boolean);

    return {
      currentPath: parts.join('/'),
      currentPathParts: parts,
    };
  }, [location]);
};

export default useCurrentPath;
