import { Box, Typography } from '@mui/material';
import { GetShopResponse } from 'api/dtos/payment/shop/dto/response';
import { EProductID } from 'api/dtos/payment/shop/enums';
import ProductCard from 'components/features/ShopModal/components/ProductCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PRODUCT_ID = EProductID.FPRE_LAGE_GEMEINDE;

interface ProductFpreLageGemeindeProps {
  shopData: GetShopResponse;
}

const ProductFpreLageGemeinde = (props: ProductFpreLageGemeindeProps) => {
  const { shopData } = props;
  const product = shopData[PRODUCT_ID];

  const { t } = useTranslation();

  return (
    <ProductCard product={product}>
      <Box sx={{ p: 3, pt: 0 }}>
        <Typography variant='body2' color='textSecondary'>
          {t(`shop:products.${PRODUCT_ID}.description`)}
        </Typography>
      </Box>
    </ProductCard>
  );
};

export default ProductFpreLageGemeinde;
