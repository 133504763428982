import React from 'react';
// @ts-ignore
import Lightbox from 'react-datatrans-light-box';
import { useDispatch, useSelector } from 'react-redux';
import { shopCheckoutSelector } from 'state/selectors/shop';
import { setShopCheckoutDatatrans, setShopCheckoutDatatransLoading } from 'state/slices/shop';

const isProduction = import.meta.env.REACT_APP_API_MAIN_URL?.includes('api.amenti.ch');

const Datatrans = () => {
  const { datatrans } = useSelector(shopCheckoutSelector);
  const { opened, transactionId } = datatrans;

  const dispatch = useDispatch();

  const onLoaded = () => {
    setShopCheckoutDatatransLoading(false);
  };

  const onCancelled = () => {
    dispatch(
      setShopCheckoutDatatrans({
        transactionId: '',
        opened: false,
        loading: false,
      }),
    );
  };

  const onError = (e: any) => {
    console.error(e);
    onCancelled();
  };

  if (!opened) return null;

  return (
    <Lightbox
      transactionId={transactionId}
      production={isProduction}
      onLoaded={onLoaded}
      onCancelled={onCancelled}
      onError={onError}
    />
  );
};

export default Datatrans;
