import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { toastListener } from 'state/utils/toastListener';
import { backendApi, featureservApi, geoAdminApi, lawApi } from './services';
import {
  appSlice,
  authSlice,
  cityViewSlice,
  compareSlice,
  dashboardSlice,
  exploreSlice,
  lawSelectSlice,
  layoutSlice,
  librarySlice,
  mapSlice,
  modalsSlice,
  projectSlice,
  routerSlice,
  shopSlice,
} from './slices';

const logger = createLogger({
  level: 'info',
  collapsed: true,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'signOut/fulfilled') {
    state = {} as RootState;
  }

  toastListener(action);

  return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  cityView: cityViewSlice.reducer,
  compare: compareSlice.reducer,
  dashboard: dashboardSlice.reducer,
  explore: exploreSlice.reducer,
  lawSelect: lawSelectSlice.reducer,
  layout: layoutSlice.reducer,
  library: librarySlice.reducer,
  map: mapSlice.reducer,
  modals: modalsSlice.reducer,
  project: projectSlice.reducer,
  router: routerSlice.reducer,
  shop: shopSlice.reducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [geoAdminApi.reducerPath]: geoAdminApi.reducer,
  [lawApi.reducerPath]: lawApi.reducer,
  [featureservApi.reducerPath]: featureservApi.reducer,
});

let middlewares = [backendApi.middleware, geoAdminApi.middleware, lawApi.middleware, featureservApi.middleware];

if (import.meta.env.REACT_APP_DOMAIN !== 'production') {
  middlewares = [logger, ...middlewares];
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

export default store;
