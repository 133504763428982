import DemoModalContent from 'components/modals/DemoModal/DemoModalContent';
import Modal from 'components/modals/Modal';
import useModals from 'hooks/useModals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EModal } from 'types/enums/Modal';

const DemoModal = () => {
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.DEMO));
  };

  return (
    <Modal
      open={modals.demo.open}
      handleClose={handleCloseModal}
      ContentSx={{ maxWidth: 'none !important' }}
      ContentWrapperSx={{ p: 0 }}
    >
      <DemoModalContent />
    </Modal>
  );
};

export default DemoModal;
