export enum EZoneType {
  LIVING_ZONE = 'W',
  WORKING_ZONE = 'A',
  MIXED_ZONE = 'M',
  CENTRAL_ZONE = 'Z',
  PUBLIC_ZONE = 'ON',
  RESTRICTED_CONSTRUCTION_ZONE = 'EB',
  TOURISM_ZONE = 'T',
  TRAFFIC_ZONE = 'V',
  OTHER_CONSTRUCTION_ZONE = 'WB',
  UNKNOWN_ZONE = 'U',
  NON_CONSTRUCTION_ZONE = 'NB',
}
