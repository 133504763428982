import { Box, Button, Chip, Link, Skeleton, Typography } from '@mui/material';
import { IDiscount } from 'api/dtos/payment/purchase/output';
import useShopLocation from 'components/features/ShopModal/hooks/useShopLocation';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import UNITS from 'constants/units';
import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shopCartItemsSelector, shopCheckoutSelector } from 'state/selectors/shop';
import { useLazyGetCheckoutCartQuery } from 'state/services/backend/endpoints/shop/shop';
import { setShopCheckoutVoucher } from 'state/slices/shop';
import Tooltip from 'theme/components/display/Tooltip/Tooltip';
import LargeField from 'theme/components/inputs/LargeField';
import { formatNumber } from 'utils/formatters';

const getErrorMessageKey = (t: TFunction, error?: string) => {
  if (!error) return null;

  switch (error) {
    case 'USED':
      return t('shop:views.checkout.voucher.errors.used');
    case 'NOT_VALID':
      return t('shop:views.checkout.voucher.errors.notValid');
    default:
      return t('shop:views.checkout.voucher.errors.error');
  }
};

interface VoucherRedeemerProps {
  isLoading: boolean;
  currentDiscount?: IDiscount;
}

const VoucherRedeemer = (props: VoucherRedeemerProps) => {
  const { currentDiscount } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cartItems = useSelector(shopCartItemsSelector);
  const { voucher } = useSelector(shopCheckoutSelector);
  const location = useShopLocation();
  const { egrids = [] } = location ?? {};
  const plots = egrids.map((egrid) => ({
    egrid,
  }));

  const [fieldVoucher, setFieldVoucher] = useState(voucher || '');
  const [open, setOpen] = useState(false);

  const [
    getCheckoutCart,
    { data: checkoutCartData, isLoading: isGetCheckoutCartLoading, isFetching: isGetCheckoutCartFetching },
  ] = useLazyGetCheckoutCartQuery();

  const handleSetVoucher = async () => {
    getCheckoutCart({
      voucher: fieldVoucher,
      products: cartItems,
      plots,
    })
      .unwrap()
      .then((response) => {
        if (!response.total.discount?.error) {
          dispatch(setShopCheckoutVoucher(fieldVoucher));
        }
      });
  };

  const handleRemoveVoucher = () => {
    dispatch(setShopCheckoutVoucher(''));
  };

  const openField = () => setOpen(true);

  useEffect(() => {
    setFieldVoucher(voucher || '');
  }, [voucher]);

  useEffect(() => {
    if (currentDiscount?.title?.length) {
      setOpen(false);
    }

    if (currentDiscount?.error) {
      dispatch(setShopCheckoutVoucher(''));
    }
  }, [currentDiscount]);

  const isLoading = isGetCheckoutCartLoading || isGetCheckoutCartFetching;

  const errorMessageKey = getErrorMessageKey(t, checkoutCartData?.total.discount?.error);

  return (
    <LoadingWrapper loading={isLoading} size={20} color='grey.500'>
      <Box>
        {open ? (
          <>
            <LargeField
              autoFocus
              error={!!errorMessageKey}
              fullWidth
              placeholder={t('shop:views.checkout.voucher.label')}
              value={fieldVoucher}
              onChange={(e) => setFieldVoucher(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  if (fieldVoucher.length) {
                    handleSetVoucher();
                  } else {
                    setOpen(false);
                  }
                } else if (e.key === 'Escape') {
                  setOpen(false);
                }
              }}
              type='text'
              InputProps={{
                endAdornment: (
                  <Box sx={{ my: 2, mr: -2 }}>
                    {!!fieldVoucher.length ? (
                      <Button onClick={handleSetVoucher} size='small' color='secondary' variant='contained'>
                        {t('shop:views.checkout.voucher.buttonText')}
                      </Button>
                    ) : (
                      <Tooltip title={t('shop:views.checkout.voucher.tooltipText')} arrow>
                        <Box>
                          <Button size='small' color='primary' variant='contained' disabled={true}>
                            {t('shop:views.checkout.voucher.buttonText')}
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                ),
              }}
            />
            {errorMessageKey && (
              <Typography variant='body2' color='error' sx={{ mt: 1 }}>
                {t(errorMessageKey)}
              </Typography>
            )}
          </>
        ) : (
          !currentDiscount?.title?.length && (
            <Link component='button' variant='body1' color='textSecondary' onClick={openField}>
              {t('shop:views.checkout.voucher.openButtonText')}
            </Link>
          )
        )}
        {!!currentDiscount?.title?.length && (
          <>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Typography color='textSecondary' variant='body1'>
                  {t('shop:general.discount')}
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <Chip label={currentDiscount.title} color='primary' size='small' onDelete={handleRemoveVoucher} />
                </Box>
              </Box>
              <Box ml={2}>
                <Box display='flex' justifyContent='flex-end'>
                  {isLoading ? (
                    <Skeleton width={60} height={25} />
                  ) : (
                    <Typography color='textPrimary' variant='body1' fontWeight={500}>
                      -{currentDiscount.saved ? formatNumber(currentDiscount.saved / 100, { decimals: 2 }) : 0}{' '}
                      {UNITS.CURRENCY}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </LoadingWrapper>
  );
};

export default VoucherRedeemer;
