import { Theme } from '@mui/material';

const styles = {
  outlinedAccordion: {
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    overflow: 'hidden',
  },
  outlinedHeader: {
    p: 3,
  },
  header: {
    position: 'relative',
    '&:focus-visible': {
      outlineStyle: 'solid',
      outlineWidth: 2,
      outlineOffset: 2,
      outlineRadius: 2,
      outlineColor: (theme: Theme) => theme.palette.blue[200],
    },
  },
  headerClickable: {
    cursor: 'pointer',
  },
  content: {},
  contentPadding: {
    p: 3,
    pt: 0,
  },
  arrow: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    right: (theme: Theme) => theme.spacing(2),
    transform: 'translateY(-50%)',
  },
  arrowRight: {
    right: 0,
  },
  arrowIcon: {
    transition: (theme: Theme) => theme.transitions.create('transform'),
  },
  arrowIconActive: {
    color: 'grey.700',
    transform: 'rotate(180deg)',
  },
};

export default styles;
