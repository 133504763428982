import React from 'react';

/*
 * This component needs to be identical to the loader inside index.html
 */

const LogoSpinner = () => {
  return (
    <div className='full-page-loader'>
      <svg
        className='full-page-loader__icon'
        aria-hidden='true'
        fill='none'
        focusable='false'
        height='16'
        viewBox='0 0 16 16'
        width='16'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          clipRule='evenodd'
          d='M0 0h16v16H0V0zm7.138 3.808l-3.76 8.395h1.71c1.723-3.795.984-2.21 2.91-6.63 1.943 4.464 1.157 2.737 2.897 6.63h1.724L8.862 3.802l-1.724.006z'
          fillRule='evenodd'
        ></path>
      </svg>
      <span className='full-page-loader__progress' role='progressbar'>
        <span className='full-page-loader__progress__bar full-page-loader__progress__bar--one'></span>
        <span className='full-page-loader__progress__bar full-page-loader__progress__bar--two'></span>
      </span>
    </div>
  );
};

export default LogoSpinner;
