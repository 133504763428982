import React from 'react';
import { alpha, Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import ActionConfirmation from '../ActionConfirmation';
import { ActionConfirmationProps as IActionConfirmationProps } from '../ActionConfirmation/ActionConfirmation';

interface IContextMenuItemWrapperProps {
  children: JSX.Element;
  handleClick: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  ActionConfirmationProps?: Omit<IActionConfirmationProps, 'onAccept'>;
}

const ContextMenuItemWrapper = (props: IContextMenuItemWrapperProps) => {
  return props.ActionConfirmationProps ? (
    <ActionConfirmation
      {...props.ActionConfirmationProps}
      onAccept={(event) => props.handleClick(event)}
      sx={{ display: 'block' }}
    >
      {props.children}
    </ActionConfirmation>
  ) : (
    props.children
  );
};

export interface IMenuItem extends BoxProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  color?: string;
  disabled?: boolean;
  Icon?: any;
  TypographyProps?: TypographyProps;
  ActionConfirmationProps?: Omit<IActionConfirmationProps, 'onAccept'>;
}

interface ContextMenuItemProps {
  handleClose: () => void;
  options: IMenuItem;
}

const ContextMenuItem = (props: ContextMenuItemProps) => {
  const { handleClose, options } = props;
  const { label, color, onClick, disabled, Icon, TypographyProps, ActionConfirmationProps, sx, ...otherProps } =
    options;

  const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    onClick(event);
    handleClose();
  };

  return (
    <ContextMenuItemWrapper handleClick={handleClick} ActionConfirmationProps={ActionConfirmationProps}>
      <Box
        onClick={ActionConfirmationProps ? undefined : handleClick}
        sx={{
          pl: 3,
          pr: 3,
          py: 2,
          borderBottom: 1,
          borderColor: 'grey.300',
          bgcolor: 'grey.100',
          transition: (theme) => theme.transitions.create('background-color'),
          '&:last-child': {
            borderBottom: 0,
          },
          '&:hover': {
            bgcolor: (theme) => (color === 'error' ? alpha(theme.palette.error.main, 0.1) : 'grey.A100'),
          },
          ...sx,
          cursor: disabled ? 'auto' : 'pointer',
          pointerEvents: disabled ? 'none' : 'all',
        }}
        {...otherProps}
      >
        <Typography
          variant='body2'
          {...TypographyProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            transition: (theme) => theme.transitions.create('opacity'),
            color: (theme) => (color === 'error' ? theme.palette.error.main : 'textPrimary'),
            ...TypographyProps?.sx,
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {Icon && (
            <Box sx={{ display: 'flex', mr: 3 }} component='span'>
              <Icon fontSize='small' color='inherit' sx={{ fontSize: 16 }} />
            </Box>
          )}
          {label}
        </Typography>
      </Box>
    </ContextMenuItemWrapper>
  );
};

export default ContextMenuItem;
