import { useMediaQuery } from '@mui/material';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import theme from 'theme/theme';

const useDevice = () => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const { isLandscape } = useMobileOrientation();

  const isMobileDevice = isMobile || isTablet;

  const isDesktop = !isMobileDevice && isMd;

  return {
    isSm,
    isMd,
    isLg,
    isXl,
    isDesktop,
    isMobile: !isDesktop,
    isTablet,
    isOnlyMobile: isMobile && !isTablet,
    isMobileDevice,
    isMobileLandscape: isMobileDevice && !isMd && isLandscape,
  };
};

export default useDevice;
