import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { EProductID } from 'api/dtos/payment/shop/enums';
import { shopApiSlice } from 'state/services/backend/endpoints/shop/shop';

const PRODUCT_MAP: Record<string, string[]> = {
  [EProductID.FEASIBILITY_STUDY]: [EProductID.PLOT_REPORT],
  [EProductID.FPRE_TOTAL_PACKAGE]: [
    EProductID.FPRE_LAGE_PARZELLE,
    EProductID.FPRE_LAGE_GEMEINDE,
    EProductID.FPRE_DESIGN_KOSTEN,
    EProductID.FPRE_PROJECT_KOSTEN,
    EProductID.FPRE_PROJECT_RENDITE,
  ],
};

const getRelatedProducts = (productId: EProductID) => {
  let parentProduct: string | null = null;
  let childProducts: string[] = [];

  for (const parent in PRODUCT_MAP) {
    if (parent === productId) {
      childProducts = PRODUCT_MAP[parent];
    } else if (PRODUCT_MAP[parent].includes(productId)) {
      parentProduct = parent;
    }
  }

  return { parentProduct, childProducts };
};

export interface ICheckout {
  datatrans: IDatatransParams;
  voucher: string | null;
}

export interface ShopState {
  cartItems: EProductID[];
  billingDetails?: IBillingDetails;
  checkout: ICheckout;
}

export interface IBillingDetails {
  email: string;
  fname: string;
  lname: string;
  company: string | null;
  address: string;
  postal: string;
  city: string;
}

interface IDatatransParams {
  loading: boolean;
  opened: boolean;
  transactionId: string;
}

const initialState: ShopState = {
  cartItems: [],
  billingDetails: {
    email: '',
    fname: '',
    lname: '',
    company: '',
    address: '',
    postal: '',
    city: '',
  },
  checkout: {
    voucher: '',
    datatrans: {
      loading: false,
      opened: false,
      transactionId: '',
    },
  },
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    clearShop: () => initialState,
    addItemToShopCartItems(state, action: PayloadAction<EProductID>) {
      const { parentProduct, childProducts } = getRelatedProducts(action.payload);

      // Remove parent product if child product is added and vice versa
      let updatedProducts = state.cartItems.filter(
        (productId) => productId !== parentProduct && !childProducts.includes(productId),
      );

      if (!updatedProducts.includes(action.payload)) {
        updatedProducts.push(action.payload);
      }

      state.cartItems = updatedProducts;
    },
    removeItemToShopCartItems(state, action: PayloadAction<EProductID>) {
      state.cartItems = state.cartItems.filter((item) => item !== action.payload);
    },
    setCartItems(state, action: PayloadAction<EProductID[]>) {
      state.cartItems = action.payload;
    },
    setShopBillingDetails(state, action: PayloadAction<IBillingDetails>) {
      state.billingDetails = action.payload;
    },
    setShopCheckoutVoucher(state, action: PayloadAction<string>) {
      state.checkout.voucher = action.payload;
    },
    setShopCheckoutDatatrans(state, action: PayloadAction<IDatatransParams>) {
      state.checkout.datatrans = action.payload;
    },
    setShopCheckoutDatatransLoading(state, action: PayloadAction<boolean>) {
      state.checkout.datatrans.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(shopApiSlice.endpoints.initializePurchase.matchPending), (state) => {
      state.checkout.datatrans.loading = true;
    });
    builder.addMatcher(isAnyOf(shopApiSlice.endpoints.initializePurchase.matchRejected), (state) => {
      state.checkout.datatrans.loading = false;
    });
    builder.addMatcher(isAnyOf(shopApiSlice.endpoints.initializePurchase.matchFulfilled), (state, action) => {
      state.checkout.datatrans.opened = true;
      state.checkout.datatrans.transactionId = action.payload.transactionId;
    });
  },
});

export const {
  addItemToShopCartItems,
  clearShop,
  removeItemToShopCartItems,
  setCartItems,
  setShopBillingDetails,
  setShopCheckoutDatatrans,
  setShopCheckoutDatatransLoading,
  setShopCheckoutVoucher,
} = shopSlice.actions;
