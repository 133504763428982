import { SvgIcon, SvgIconProps } from '@mui/material';

const CircleArrowUp = (props: SvgIconProps) => (
  <SvgIcon width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5ZM0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.597 5.03392L6.95035 9.34355C6.77198 9.55435 6.79827 9.86984 7.00907 10.0482C7.21987 10.2266 7.53536 10.2003 7.71373 9.98949L10.498 6.69895V16.9992C10.498 17.2753 10.7219 17.4992 10.998 17.4992C11.2742 17.4992 11.498 17.2753 11.498 16.9992V6.69737L14.2837 9.98949C14.4621 10.2003 14.7775 10.2266 14.9883 10.0482C15.1992 9.86984 15.2254 9.55435 15.0471 9.34355L11.3804 5.01022C11.3709 4.99899 11.361 4.98825 11.3506 4.97799C11.2602 4.88808 11.1356 4.83252 10.998 4.83252C10.8338 4.83252 10.6881 4.91168 10.597 5.03392Z'
    />
  </SvgIcon>
);

export default CircleArrowUp;
