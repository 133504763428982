import { skipToken } from '@reduxjs/toolkit/query';
import useAuthentication from 'hooks/useAuthentication';
import { ELanguage } from 'i18n';
import moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { appLanguageSelector } from 'state/selectors/app';
import { backendApi } from 'state/services';
import { useGetUserQuery } from 'state/services/backend/endpoints/user/user';
import { EBackendTag } from 'state/services/backend/types';
import { setLanguage } from 'state/slices/app';

const momentLanguageMap: Record<string, string> = {
  en: 'en-gb',
  de: 'de',
  fr: 'fr',
  it: 'it',
};

const LanguageDetector = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuthentication();
  const appLanguage = useSelector(appLanguageSelector);

  const { data } = useGetUserQuery(isAuthenticated ? undefined : skipToken);
  const { language: userLanguage } = data ?? {};

  moment.locale(momentLanguageMap[i18n.language]);

  useEffect(() => {
    if (!!userLanguage && i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage).then(() => {});
    }

    // Invalidate to refetch data with new language
    dispatch(backendApi.util.invalidateTags([EBackendTag.WITH_LANGUAGE]));
  }, [userLanguage]);

  useEffect(() => {
    if (i18n.language !== appLanguage) {
      dispatch(setLanguage(i18n.language as ELanguage));
    }
  }, [i18n.language]);

  return null;
};

export default LanguageDetector;
