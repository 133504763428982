import { Box, Button, Grid } from '@mui/material';
import ProjectsSelect from 'pages/Dashboard/Projects/components/ProjectsSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDashboardProjectsQuery } from 'state/services/backend/endpoints/project/dashboard';
import { IDashboardListProject } from 'types/project/project';

export enum EFahrlanderRequestType {
  PROJECT = 'project',
  SUBSCRIPTION = 'subscription',
}

interface FahrlanderRequestToolbarProps {
  requestType: EFahrlanderRequestType;
  setRequestType: (type: EFahrlanderRequestType) => void;
  selectedProjectId: string | undefined;
  setSelectedProjectId: (id: string | undefined) => void;
}

const FahrlanderRequestToolbar = (props: FahrlanderRequestToolbarProps) => {
  const { requestType, setRequestType, selectedProjectId, setSelectedProjectId } = props;

  const { t } = useTranslation();

  const { data: dashboardProjectsData } = useGetDashboardProjectsQuery({
    pagination: { limit: 99999, page: 1 },
    search: '',
    plotFilter: {
      plotNumber: '',
      municipality: '',
    },
    shared: false,
  });

  const handleAutocompleteChange = (_e: React.SyntheticEvent, value: string | IDashboardListProject | null) => {
    if (typeof value === 'object') {
      setSelectedProjectId(value?.id ?? undefined);
    }
  };

  const autocompleteOptions = dashboardProjectsData?.projects ?? [];
  const autocompleteValue = autocompleteOptions.find((item) => item.id === selectedProjectId) || undefined;

  return (
    <Box sx={{ mb: 6 }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            variant={requestType === EFahrlanderRequestType.SUBSCRIPTION ? 'contained' : 'outlined'}
            color={requestType === EFahrlanderRequestType.SUBSCRIPTION ? 'secondary' : undefined}
            sx={{ borderRadius: 1, height: 40 }}
            onClick={() => setRequestType(EFahrlanderRequestType.SUBSCRIPTION)}
            fullWidth
          >
            {t('general:subscription')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant={requestType === EFahrlanderRequestType.PROJECT ? 'contained' : 'outlined'}
            color={requestType === EFahrlanderRequestType.PROJECT ? 'secondary' : undefined}
            sx={{ borderRadius: 1, height: 40 }}
            onClick={() => setRequestType(EFahrlanderRequestType.PROJECT)}
            fullWidth
          >
            {t('general:project')}
          </Button>
        </Grid>
        {requestType === EFahrlanderRequestType.PROJECT && (
          <Grid item xs={12}>
            <ProjectsSelect
              onChange={handleAutocompleteChange}
              value={autocompleteValue}
              options={autocompleteOptions}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FahrlanderRequestToolbar;
