import { QueryParameters } from 'types/request';

const encodeArray = (key: string, array: string[] | number[], isDelimitedArray?: boolean): null | string => {
  if (!array.length) return null;
  return isDelimitedArray
    ? `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(array))}`
    : array.map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
};

const encodeLiteral = (key: string, value: string | number | boolean) => {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

export const query = (params: QueryParameters, isDelimitedArray?: boolean): string =>
  Object.entries(params)
    .map(([key, value]) => {
      if (value === undefined) return null;
      return Array.isArray(value) ? encodeArray(key, value, isDelimitedArray) : encodeLiteral(key, value);
    })
    .filter(Boolean)
    .join('&');

export const getQueryParameters = (queryParameters?: QueryParameters, isDelimitedArray?: boolean) =>
  queryParameters ? `?${query(queryParameters, isDelimitedArray)}` : '';
