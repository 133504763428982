import ResourcesDE from './de';
import ResourcesEN from './en';
import ResourcesFR from './fr';
import ResourcesIT from './it';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export enum ELanguage {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  IT = 'it',
  ET = 'et',
}

export const LANGUAGE_NAMES = {
  [ELanguage.DE]: 'Deutsch',
  [ELanguage.EN]: 'English',
  [ELanguage.FR]: 'Français',
  [ELanguage.IT]: 'Italiano',
} as const;

export const AVAILABLE_LANGUAGES = [ELanguage.DE, ELanguage.EN, ELanguage.FR, ELanguage.IT] as const;

export const resources = {
  [ELanguage.DE]: ResourcesDE,
  [ELanguage.EN]: ResourcesEN,
  [ELanguage.FR]: ResourcesFR,
  [ELanguage.IT]: ResourcesIT,
} as const;

export const DEFAULT_LANGUAGE = ELanguage.DE;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
