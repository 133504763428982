import { Theme } from '@mui/material';

const styles = {
  field: {
    '& > .MuiOutlinedInput-root': {
      bgcolor: 'grey.100',
      borderRadius: 50,
      '& .MuiOutlinedInput-input': {},
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        transition: (theme: Theme) => theme.transitions.create('border-color'),
      },
      '& .MuiInputAdornment-root': {
        '&.MuiInputAdornment-positionStart': {
          mr: 1,
        },
        '&.MuiInputAdornment-positionEnd': {
          ml: 1,
        },
      },
      '&:hover, &.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: 'grey.600',
        },
      },
      '&.MuiInputBase-adornedStart': {
        pl: 2,
      },
      '&.MuiInputBase-adornedEnd': {
        pr: 1,
      },
    },
  },
};

export default styles;
