import { SvgIcon, SvgIconProps } from '@mui/material';

const LockLight = (props: SvgIconProps) => (
  <SvgIcon
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 128 128'
    enableBackground='new 0 0 128 128'
    xmlSpace='preserve'
    {...props}
  >
    <path d='M64 80a2 2 0 0 0-2 2v20a2 2 0 0 0 4 0V82a2 2 0 0 0-2-2z' />
    <path d='M96 58h-4V30C92 14.561 79.439 2 64 2S36 14.561 36 30v28h-4c-7.72 0-14 6.28-14 14v40c0 7.72 6.28 14 14 14h64c7.72 0 14-6.28 14-14V72c0-7.72-6.28-14-14-14zM40 30C40 16.766 50.766 6 64 6c13.233 0 24 10.766 24 24v28H40V30zm66 82c0 5.514-4.486 10-10 10H32c-5.514 0-10-4.486-10-10V72c0-5.514 4.486-10 10-10h64c5.514 0 10 4.486 10 10v40z' />
  </SvgIcon>
);

export default LockLight;
