import useModals from 'hooks/useModals';
import React from 'react';
import { PopupModal } from 'react-calendly';
import { useDispatch } from 'react-redux';
import { EModal } from 'types/enums/Modal';
import { options } from 'utils/calendly';

const CalendlyModal = () => {
  const dispatch = useDispatch();
  const { modals, closeModal } = useModals();

  const handleClose = () => dispatch(closeModal(EModal.CALENDLY));

  const rootElement = document.getElementById('root') as HTMLElement;

  return (
    <PopupModal
      url={options.url}
      pageSettings={options.pageSettings}
      onModalClose={handleClose}
      open={modals.calendly.open}
      rootElement={rootElement}
    />
  );
};

export default CalendlyModal;
